import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import MDButton from 'components/MDButton';
import MDInput from "components/MDInput";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Oval } from 'react-loader-spinner';
import Select from "react-select";
import * as service from '../../../services/service';

function Schemes() {
    const [state, setState] = useState({
        scheme: '', totaldischarge: '', risingmains: '', length: '', diameter: '',
        thickness: '', staichead: '', ratingofmotors: '', pumpoperatinglevel: '',
        fullreservoirlevel: '', minimumdrawdownlevel: '', sumpbottomlevel: '',
        stagewiseirrigatedarea: '', totalirrigatedarea: ''
    });
    const [data, setData] = useState([]);
    const [CEOData, setCEOData] = useState([]);
    const [SEOData, setSEOData] = useState([]);
    const [CivilDivisionData, setCivilDivisionData] = useState([]);
    const [CivilSubDivisionData, setCivilSubDivisionData] = useState([]);
    const [CEOId, setCEOId] = useState('');
    const [SEOId, setSEOId] = useState('');
    const [CivilDivisionId, setCivilDivisionId] = useState('');
    const [CivilSubDivisionId, setCivilSubDivisionId] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

    const getCEOs = () => {
        fetch(
            service.CIVIL_CEO_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.civilCEOId, label: item.civilCEO }));
                setCEOData(data2);
            });
    };

    const getSEOs = (id) => {
        fetch(
            service.GET_CIVIL_SEO_BY_CEO_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CivilCEOId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.civilSEOId, label: item.civilSEO }));
                setSEOData(data2);
            });
    };

    const getCivilDivisions = (id) => {
        fetch(
            service.GET_CIVIL_DIVISION_BY_SEO_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CivilSEOId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.civilDivisionId, label: item.civilDivision }));
                setCivilDivisionData(data2);
            });
    };

    const getCivilSubDivisions = (id) => {
        fetch(
            service.GET_CIVIL_SUB_DIVISION_BY_CIVIL_DIV_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CivilDivisionId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.civilSubDivisionId, label: item.civilSubDivision }));
                setCivilSubDivisionData(data2);
            });
    };

    const getDetails = (ceoId, seoId, divId, subId) => {
        const errs = {};
        fetch(
            service.SCHEME_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CivilCEOId: ceoId,
                    CivilSEOId: seoId,
                    CivilDivisionId: divId,
                    CivilSubDivisionId: subId
                })
            })
            .then(res => res.json())
            .then(result => {
                setData(result);
            })
            .catch(err => {
                if (err) {
                    errs[".invalid"] = err;
                    setErrors(err);
                };
            });
    };

    const getDetailsById = (id) => {
        setLoading(true);
        const errs = {};
        fetch(
            service.GET_SCHEME_BY_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    SchemeId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.message.messageType === 'Success') {
                    setState({
                        ...state, scheme: result.scheme, totaldischarge: result.totalDischarge, risingmains: result.risingMains,
                        length: result.length, diameter: result.diameter, thickness: result.thickness, staichead: result.staicHead,
                        ratingofmotors: result.ratingOfMotors, pumpoperatinglevel: result.pumpOperatingLevel, fullreservoirlevel: result.fullReservoirLevel,
                        minimumdrawdownlevel: result.minimumDrawDownLevel, sumpbottomlevel: result.sumpBottomLevel, stagewiseirrigatedarea: result.stageWiseIrrigatedArea,
                        totalirrigatedarea: result.totalIrrigatedArea
                    });

                    const ceData = ({ value: result.civilCEOId, label: result.civilCEO });
                    setCEOId(ceData);
                    getSEOs(ceData.value);
                    const seData = ({ value: result.civilSEOId, label: result.civilSEO });
                    setSEOId(seData);
                    getCivilDivisions(seData.value);
                    const divData = ({ value: result.civilDivisionId, label: result.civilDivision });
                    setCivilDivisionId(divData);
                    getCivilSubDivisions(divData.value);
                    const subData = ({ value: result.civilSubDivisionId, label: result.civilSubDivision });
                    setCivilSubDivisionId(subData);
                }
                else {
                    errs[".invalid"] = result.message.message;
                    setErrors(errs);
                }
            });
        setLoading(false);
    };
    const handleEdit = (id) => {
        getDetailsById(id);
        localStorage.setItem("ID", JSON.stringify(id));
    }

    useEffect(() => {
        setLoading(true);
        getCEOs();
        setLoading(false);
    }, []);

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!state.scheme) {
            isValid = false;
            errs[".scheme"] = "Please enter scheme name";
        }
        if (!CEOId) {
            isValid = false;
            errs[".ceo"] = "Please select Civil CEO";
        }
        setErrors(errs);
        return isValid;
    }

    const changeHandler = (event) => {
        if (event.target.name !== 'scheme') {
            if (Number.isNaN(parseFloat(event.target.value))) {
                // eslint-disable-next-line
                alert("Please enter numbers only");
                event.target.value = ""; // eslint-disable-line no-param-reassign
            }
        }
        setState({ ...state, [event.target.name]: event.target.value });
    };
    const CEOChangeHandler = (ev) => {
        setCEOId(ev);
        getSEOs(ev.value);
        getDetails(ev.value, 0, 0, 0);
    }
    const SEOChangeHandler = (ev) => {
        setSEOId(ev);
        setCivilDivisionData([]);
        getCivilDivisions(ev.value);
        getDetails(CEOId.value, ev.value, 0, 0);
    }
    const CivilDivisionChangeHandler = (ev) => {
        setCivilDivisionId(ev);
        setCivilSubDivisionData([]);
        getCivilSubDivisions(ev.value);
        getDetails(CEOId.value, SEOId.value, ev.value, 0);
    }
    const CivilSubDivisionChangeHandler = (ev) => {
        setCivilSubDivisionId(ev);
        getDetails(CEOId.value, SEOId.value, CivilDivisionId.value, ev.value);
    }

    const clearHandler = () => {
        localStorage.setItem('ID', null);
        setState({
            ...state, scheme: '', totaldischarge: '', risingmains: '', length: '', diameter: '',
            thickness: '', staichead: '', ratingofmotors: '', pumpoperatinglevel: '',
            fullreservoirlevel: '', minimumdrawdownlevel: '', sumpbottomlevel: '',
            stagewiseirrigatedarea: '', totalirrigatedarea: ''
        });
        const ce = ({ value: null, label: "Select CEO" });
        setCEOId(ce);
        const se = ({ value: null, label: "Select SEO" });
        setSEOId(se);
        const div = ({ value: null, label: "Select Division" });
        setCivilDivisionId(div);
        const sub = ({ value: null, label: "Select Sub Division" });
        setCivilSubDivisionId(sub);

        setData([]);
    }

    const submitHandler = e => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        if (validate()) {
            fetch(
                service.ADD_UPDATE_SCHEME,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        SchemeId: JSON.parse(localStorage.getItem('ID')) !== null ? JSON.parse(localStorage.getItem('ID')) : 0,
                        Scheme: state.scheme,
                        TotalDischarge: state.totaldischarge,
                        RisingMains: state.risingmains,
                        Length: state.length,
                        Diameter: state.diameter,
                        Thickness: state.thickness,
                        StaicHead: state.staichead,
                        RatingOfMotors: state.ratingofmotors,
                        PumpOperatingLevel: state.pumpoperatinglevel,
                        FullReservoirLevel: state.fullreservoirlevel,
                        MinimumDrawDownLevel: state.minimumdrawdownlevel,
                        SumpBottomLevel: state.sumpbottomlevel,
                        StageWiseIrrigatedArea: state.stagewiseirrigatedarea,
                        TotalIrrigatedArea: state.totalirrigatedarea,
                        CivilCEOId: CEOId.value,
                        CivilSEOId: SEOId ? SEOId.value : 0,
                        CivilDivisionId: CivilDivisionId ? CivilDivisionId.value : 0,
                        CivilSubDivisionId: CivilSubDivisionId ? CivilSubDivisionId.value : 0,
                        AddedBy: JSON.parse(sessionStorage.getItem('userData')).userId,
                        UpdatedBy: JSON.parse(sessionStorage.getItem('userData')).userId,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                        localStorage.setItem('ID', null);
                        // window.location.reload();
                        setState({ ...state, scheme: '', totaldischarge: '', risingmains: '', length: '', diameter: '',
                        thickness: '', staichead: '', ratingofmotors: '', pumpoperatinglevel: '',
                        fullreservoirlevel: '', minimumdrawdownlevel: '', sumpbottomlevel: '',
                        stagewiseirrigatedarea: '', totalirrigatedarea: '' });
                        getDetails(CEOId.value, SEOId ? SEOId.value : 0, CivilDivisionId ? CivilDivisionId.value : 0, CivilSubDivisionId ? CivilSubDivisionId.value : 0);
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                    }
                    else {
                        errs[".invalid"] = result.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Scheme Details
                                </MDTypography>
                            </MDBox>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                <Select
                                                    name="Civil CEO"
                                                    options={CEOData} placeholder="Select CEO"
                                                    value={CEOId}
                                                    onChange={CEOChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".ceo"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <Select
                                                    name="Civil SEO"
                                                    options={SEOData} placeholder="Select SEO"
                                                    value={SEOId}
                                                    onChange={SEOChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".seo"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Select
                                                    name="Civil Division"
                                                    options={CivilDivisionData} placeholder="Select Division"
                                                    value={CivilDivisionId}
                                                    onChange={CivilDivisionChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".mechanicaldivision"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <Select
                                                    name="Civil Sub Division"
                                                    options={CivilSubDivisionData} placeholder="Select Sub Division"
                                                    value={CivilSubDivisionId}
                                                    onChange={CivilSubDivisionChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".mechanicalsubdivision"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <MDInput label="Scheme" fullWidth value={state.scheme || ''}
                                                    name="scheme"
                                                    onChange={changeHandler}
                                                    id="scheme"
                                                    required />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".scheme"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <MDInput label="Total Discharge" fullWidth value={state.totaldischarge || ''}
                                                    name="totaldischarge"
                                                    onChange={changeHandler}
                                                    id="totaldischarge"                                                    
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".totaldischarge"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <MDInput label="Rising Mains" fullWidth value={state.risingmains || ''}
                                                    name="risingmains"
                                                    onChange={changeHandler}
                                                    id="risingmains"                                                    
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".risingmains"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <MDInput label="Length" fullWidth value={state.length || ''}
                                                    name="length"
                                                    onChange={changeHandler}
                                                    id="length"                                                    
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".length"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <MDInput label="Diameter" fullWidth value={state.diameter || ''}
                                                    name="diameter"
                                                    onChange={changeHandler}
                                                    id="diameter"                                                    
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".diameter"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <MDInput label="Thickness" fullWidth value={state.thickness || ''}
                                                    name="thickness"
                                                    onChange={changeHandler}
                                                    id="thickness"                                                    
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".thickness"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <MDInput label="Staic Head" fullWidth value={state.staichead || ''}
                                                    name="staichead"
                                                    onChange={changeHandler}
                                                    id="staichead"                                                    
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".staichead"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <MDInput label="Rating Of Motors" fullWidth value={state.ratingofmotors || ''}
                                                    name="ratingofmotors"
                                                    onChange={changeHandler}
                                                    id="ratingofmotors"                                                    
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".ratingofmotors"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <MDInput label="Pump Operating Level" fullWidth value={state.pumpoperatinglevel || ''}
                                                    name="pumpoperatinglevel"
                                                    onChange={changeHandler}
                                                    id="pumpoperatinglevel"                                                    
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".pumpoperatinglevel"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <MDInput label="Full Reservoir Level" fullWidth value={state.fullreservoirlevel || ''}
                                                    name="fullreservoirlevel"
                                                    onChange={changeHandler}
                                                    id="fullreservoirlevel"                                                    
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".fullreservoirlevel"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <MDInput label="Minimum DrawDown Level" fullWidth value={state.minimumdrawdownlevel || ''}
                                                    name="minimumdrawdownlevel"
                                                    onChange={changeHandler}
                                                    id="minimumdrawdownlevel"                                                    
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".minimumdrawdownlevel"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <MDInput label="Sump Bottom Level" fullWidth value={state.sumpbottomlevel || ''}
                                                    name="sumpbottomlevel"
                                                    onChange={changeHandler}
                                                    id="sumpbottomlevel"                                                    
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".sumpbottomlevel"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <MDInput label="Stage Wise Irrigated Area" fullWidth value={state.stagewiseirrigatedarea || ''}
                                                    name="stagewiseirrigatedarea"
                                                    onChange={changeHandler}
                                                    id="stagewiseirrigatedarea"                                                    
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".stagewiseirrigatedarea"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <MDInput label="Total Irrigated Area" fullWidth value={state.totalirrigatedarea || ''}
                                                    name="totalirrigatedarea"
                                                    onChange={changeHandler}
                                                    id="totalirrigatedarea"                                                    
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".totalirrigatedarea"]}</div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                <MDBox mt={4} mb={1} textAlign="center">
                                    {loading ? <Oval
                                        heigth="20"
                                        width="20"
                                        color='grey'
                                        ariaLabel='loading'
                                    /> :
                                        <div>
                                            <MDButton color="info" onClick={submitHandler}>
                                                SAVE
                                            </MDButton>&nbsp;&nbsp;&nbsp;&nbsp;
                                            <MDButton color="info" onClick={clearHandler}>
                                                CLEAR
                                            </MDButton>
                                        </div>
                                    }
                                    {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                </MDBox>

                            </Card>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                {loading ? <Oval
                                    heigth="100"
                                    width="100"
                                    color='grey'
                                    ariaLabel='loading'
                                /> :
                                    <TableContainer component={Paper} style={{ width: '95%', alignSelf: 'center', }}>
                                        <Table aria-label="simple table">

                                            <TableRow style={{ backgroundColor: 'lightgrey' }}>
                                                <TableCell style={{ fontWeight: 'bold' }}>Scheme</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Total Discharge</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Rising Mains</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Length</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Diameter</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Thickness</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Civil CEO</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Civil SE</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Civil Division</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Civil Sub Division</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Action</TableCell>
                                            </TableRow>

                                            <TableBody>
                                                {data.map((row) => (
                                                    <TableRow
                                                        key={row.schemeId}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell>{row.scheme}</TableCell>
                                                        <TableCell>{row.totalDischarge}</TableCell>
                                                        <TableCell>{row.risingMains}</TableCell>
                                                        <TableCell>{row.length}</TableCell>
                                                        <TableCell>{row.diameter}</TableCell>
                                                        <TableCell>{row.thickness}</TableCell>
                                                        <TableCell>{row.civilCEO}</TableCell>
                                                        <TableCell>{row.civilSEO}</TableCell>
                                                        <TableCell>{row.civilDivision}</TableCell>
                                                        <TableCell>{row.civilSubDivision}</TableCell>
                                                        <TableCell><MDButton onClick={() => handleEdit(row.schemeId)}>Edit</MDButton></TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                }
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
                <Footer />
            </div>
        </DashboardLayout>
    );
}
export default Schemes;