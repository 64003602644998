// const baseURL = 'https://localhost:44317/api/';
 const baseURL = 'https://elimsapi.egremes.in/api/';

export const LOGIN_URL = `${baseURL}Account/login`;
export const CE_URL = `${baseURL}Masters/getces`;
export const GET_CE_BY_ID = `${baseURL}Masters/getcebyid`;
export const ADD_UPDATE_CE = `${baseURL}Masters/addeditce`;
export const SE_URL = `${baseURL}Masters/getses`;
export const GET_SE_BY_CE_ID = `${baseURL}Masters/getsebyceid`;
export const GET_SE_BY_ID = `${baseURL}Masters/getsebyid`;
export const ADD_UPDATE_SE = `${baseURL}Masters/addeditse`;
export const MECHANICAL_DIVISION_URL = `${baseURL}Masters/getmechanicaldivisions`;
export const GET_MECHANICAL_DIVISION_BY_SE_ID = `${baseURL}Masters/getmechanicaldivisionbyseid`;
export const GET_MECHANICAL_DIVISION_BY_ID = `${baseURL}Masters/getmechanicaldivisionbyid`;
export const ADD_UPDATE_MECHANICAL_DIVISION = `${baseURL}Masters/addeditmechanicaldivision`;
export const MECHANICAL_SUB_DIVISION_URL = `${baseURL}Masters/getmechanicalsubdivisions`;
export const GET_MECHANICAL_SUB_DIVISION_BY_MECH_DIV_ID = `${baseURL}Masters/getmechanicalsubdivisionbymechdivid`;
export const GET_MECHANICAL_SUB_DIVISION_BY_ID = `${baseURL}Masters/getmechanicalsubdivisionbyid`;
export const ADD_UPDATE_MECHANICAL_SUB_DIVISION = `${baseURL}Masters/addeditmechanicalsubdivision`;
export const CIVIL_CEO_URL = `${baseURL}Masters/getcivilceos`;
export const GET_CIVIL_CEO_BY_ID = `${baseURL}Masters/getcivilceobyid`;
export const ADD_UPDATE_CIVIL_CEO = `${baseURL}Masters/addeditcivilceo`;
export const CIVIL_SEO_URL = `${baseURL}Masters/getcivilseos`;
export const GET_CIVIL_SEO_BY_CEO_ID = `${baseURL}Masters/getcivilseobyceoid`;
export const GET_CIVIL_SEO_BY_ID = `${baseURL}Masters/getcivilseobyid`;
export const ADD_UPDATE_CIVIL_SEO = `${baseURL}Masters/addeditcivilseo`;
export const CIVIL_DIVISION_URL = `${baseURL}Masters/getcivildivisions`;
export const GET_CIVIL_DIVISION_BY_SEO_ID = `${baseURL}Masters/getcivildivisionbyseoid`;
export const GET_CIVIL_DIVISION_BY_ID = `${baseURL}Masters/getcivildivisionbyid`;
export const ADD_UPDATE_CIVIL_DIVISION = `${baseURL}Masters/addeditcivildivision`;
export const CIVIL_SUB_DIVISION_URL = `${baseURL}Masters/getcivilsubdivisions`;
export const GET_CIVIL_SUB_DIVISION_BY_CIVIL_DIV_ID = `${baseURL}Masters/getcivilsubdivisionbycivildivid`;
export const GET_CIVIL_SUB_DIVISION_BY_ID = `${baseURL}Masters/getcivilsubdivisionbyid`;
export const ADD_UPDATE_CIVIL_SUB_DIVISION = `${baseURL}Masters/addeditcivilsubdivision`;
export const DESIGNATION_URL = `${baseURL}Masters/getdesignations`;
export const GET_DESIGNATION_BY_ID = `${baseURL}Masters/getdesignationbyid`;
export const ADD_UPDATE_DESIGNATION = `${baseURL}Masters/addeditdesignation`;
export const USERS_URL = `${baseURL}Masters/getusers`;
export const GET_ACTIVE_USERS = `${baseURL}Masters/getactiveusers`;
export const GET_USER_BY_ID = `${baseURL}Masters/getuserbyid`;
export const ADD_UPDATE_USER = `${baseURL}Masters/addedituser`;
export const UPDATE_USER_STATUS = `${baseURL}Masters/updateuserstatus`;
export const EXPENDITURE_HEAD_URL = `${baseURL}Masters/getexpenditureheads`;
export const FUND_HEAD_URL = `${baseURL}Masters/getfundheads`;
export const EXPENDITURE_COMPONENT_URL = `${baseURL}Masters/getexpenditurecomponents`;
export const EXPENDITURE_SUB_COMPONENT_URL = `${baseURL}Masters/getexpendituresubcomponents`;
export const GET_EXPENDITURE_SUB_COMPONENT_BY_COMPONENT_ID = `${baseURL}Masters/getexpendituresubcomponentbycomponentid`;
export const GET_EXPENDITURE_SUB_COMPONENT_BY_ID = `${baseURL}Masters/getexpendituresubcomponentbyid`;
export const ADD_UPDATE_EXPENDITURE_SUB_COMPONENT = `${baseURL}Masters/addeditexpendituresubcomponent`;
export const SCHEME_URL = `${baseURL}Masters/getschemes`;
export const GET_SCHEME_BY_ID = `${baseURL}Masters/getschemebyid`;
export const ADD_UPDATE_SCHEME = `${baseURL}Masters/addeditscheme`;
export const PUMP_HOUSE_URL = `${baseURL}Masters/getpumphouses`;
export const GET_PUMP_HOUSE_BY_ID = `${baseURL}Masters/getpumphousebyid`;
export const ADD_UPDATE_PUMP_HOUSE = `${baseURL}Masters/addeditpumphouse`;
export const PUMP_TYPE_URL = `${baseURL}Masters/getpumptypes`;
export const GET_PUMP_TYPE_BY_ID = `${baseURL}Masters/getpumptypebyid`;
export const ADD_UPDATE_PUMP_TYPE = `${baseURL}Masters/addeditpumptype`;
export const REASON_URL = `${baseURL}Masters/getreasons`;
export const GET_REASON_BY_ID = `${baseURL}Masters/getreasonbyid`;
export const ADD_UPDATE_REASON = `${baseURL}Masters/addeditreason`;
export const PUMP_URL = `${baseURL}Masters/getpumps`;
export const GET_PUMP_BY_ID = `${baseURL}Masters/getpumpbyid`;
export const ADD_UPDATE_PUMP = `${baseURL}Masters/addeditpump`;
export const GET_SCHEME_NOT_ALLOCATED_TO_SE = `${baseURL}Work/getschemenotalloctedtose`;
export const GET_SCHEME_ALLOCATED_TO_SE = `${baseURL}Work/getschemeallocationtose`;
export const ADD_UPDATE_SCHEME_ALLOCATION_TO_SE = `${baseURL}Work/addeditschemeallocationtose`;
export const DELETE_SCHEME_ALLOCATION_TO_SE = `${baseURL}Work/deleteschemeallocationtose`;
export const GET_SCHEME_NOT_ALLOCATED_TO_MECHDIV = `${baseURL}Work/getschemenotalloctedtomechdiv`;
export const GET_SCHEME_ALLOCATED_TO_MECHDIV = `${baseURL}Work/getschemeallocationtomechdiv`;
export const ADD_UPDATE_SCHEME_ALLOCATION_TO_MECHDIV = `${baseURL}Work/addeditschemeallocationtomechdiv`;
export const DELETE_SCHEME_ALLOCATION_TO_MECHDIV = `${baseURL}Work/deleteschemeallocationtomechdiv`;
export const GET_SCHEME_NOT_ALLOCATED_TO_MECHSUBDIV = `${baseURL}Work/getschemenotalloctedtomechsubdiv`;
export const GET_SCHEME_ALLOCATED_TO_MECHSUBDIV = `${baseURL}Work/getschemeallocationtomechsubdiv`;
export const ADD_UPDATE_SCHEME_ALLOCATION_TO_MECHSUBDIV = `${baseURL}Work/addeditschemeallocationtomechsubdiv`;
export const DELETE_SCHEME_ALLOCATION_TO_MECHSUBDIV = `${baseURL}Work/deleteschemeallocationtomechsubdiv`;
export const GET_SCHEME_ALLOCATION_TO_MECHSUBDIV_BY_SUBDIVID = `${baseURL}Work/getschemeallocationtomechsubdivbysubdivid`;
export const PUMP_WORK_DONE_URL = `${baseURL}Work/getpumpworkdone`;
export const GET_PUMP_WORK_DONE_BY_ID = `${baseURL}Work/getpumpworkdonebyid`;
export const ADD_UPDATE_PUMP_WORK_DONE = `${baseURL}Work/addeditpumpworkdone`;
export const PUMP_LOG_URL = `${baseURL}Work/getpumplogs`;
export const GET_PUMP_LOG_BY_ID = `${baseURL}Work/getpumplogbyid`;
export const ADD_UPDATE_PUMP_LOG = `${baseURL}Work/addeditpumplog`;
export const DELETE_PUMP_LOG = `${baseURL}Work/deletepumplog`;

export const EXPENDITURE_DETAILS_URL = `${baseURL}Work/getexpendituredetails`;
export const GET_PENDING_EXPENDITURE_DETAILS_URL = `${baseURL}Work/getpendingexpendituredetails`;
export const GET_EXPENDITURE_DETAILS_BY_ID = `${baseURL}Work/getexpendituredetailsbyid`;
export const ADD_UPDATE_EXPENDITURE_DETAILS = `${baseURL}Work/addeditexpendituredetails`;
export const ADD_UPDATE_EXPENDITURE_PAYMENT_DETAILS = `${baseURL}Work/addeditexpenditurepaymentdetails`;
export const GET_EXPENDITURE_PAYMENT_DETAILS = `${baseURL}Work/getexpenditurepaymentdetails`;
export const DELETE_EXPENDITURE_PAYMENT_DETAILS = `${baseURL}Work/deleteexpenditurepaymentdetails`;

export const DOWNLOAD_FILE = `${baseURL}Work/downloadfile`;

export const GET_DAILY_PUMP_UTILIZATION_REPORT = `${baseURL}Work/getdailypumputilizationreport`;
export const GET_MONTHLY_PUMP_UTILIZATION_REPORT = `${baseURL}Work/getmonthlypumputilizationreport`;
export const GET_WATER_LIFTING_REPORT = `${baseURL}Work/getwaterliftingreport`;
export const GET_EXPENDITURE_REPORT = `${baseURL}Work/getexpenditurereport`;

export default LOGIN_URL;