// import { useNavigate } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import MDButton from 'components/MDButton';
// import MDInput from "components/MDInput";
import { Oval } from 'react-loader-spinner';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as moment from 'moment';
import { Radio } from '@mui/material';
import * as service from '../../services/service';

function ExpenditurePaymentDetails() {
    const [paymentDate, setPaymentDate] = useState(new Date());
    const [FundHeadData, setFundHeadData] = useState([]);
    const [FundHeadId, setFundHeadId] = useState('');
    const [ExpenditureData, setExpenditureData] = useState([]);
    const [PaymentData, setPaymentData] = useState([]);
    const [ExpenditureId, setExpenditureId] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

    const getFundHeads = () => {
        fetch(
            service.FUND_HEAD_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.fundHeadId, label: item.fundHead }));
                setFundHeadData(data2);
            });
    };

    const getPendingExpenditures = () => {
        const errs = {};
        fetch(
            service.GET_PENDING_EXPENDITURE_DETAILS_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    MechanicalSubDivisionId: JSON.parse(sessionStorage.getItem('userData')).mechanicalSubDivisionId
                })
            })
            .then(res => res.json())
            .then(result => {
                setExpenditureData(result);
            })
            .catch(err => {
                if (err) {
                    errs[".invalid"] = err;
                    setErrors(err);
                };
            });
    };

    const getPaymentDetails = () => {
        const errs = {};
        fetch(
            service.GET_EXPENDITURE_PAYMENT_DETAILS,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    MechanicalSubDivisionId: JSON.parse(sessionStorage.getItem('userData')).mechanicalSubDivisionId
                })
            })
            .then(res => res.json())
            .then(result => {
                setPaymentData(result);
            })
            .catch(err => {
                if (err) {
                    errs[".invalid"] = err;
                    setErrors(err);
                };
            });
    };

    useEffect(() => {
        setLoading(true);
        getFundHeads();
        getPendingExpenditures();
        getPaymentDetails();
        setLoading(false);
    }, []);

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!ExpenditureId) {
            isValid = false;
            errs[".ExpenditureId"] = "Please select ExpenditureId";
        }
        if (!FundHeadId) {
            isValid = false;
            errs[".FundHeadId"] = "Please select Fund Head";
        }
        if (!paymentDate) {
            isValid = false;
            errs[".paymentDate"] = "Please select Date";
        }
        setErrors(errs);
        return isValid;
    }
    // const changeHandler = (event) => {
    //     setState({ ...state, [event.target.name]: event.target.value });
    // };

    const FundHeadChangeHandler = (ev) => {
        setFundHeadId(ev);
    }

    const handleRadioChange = (expId) => {
        setExpenditureId(expId);
    }

    const clearHandler = () => {
        // localStorage.setItem('ID', null);        admin                
        setExpenditureId('');
        const com = ({ value: null, label: "Select Fund Head" });
        setFundHeadId(com);
        setPaymentDate(new Date());
    }

    const handleDownload = (fileName, filePath) => {
        setLoading(true);
        const formData = new FormData();
        formData.append("FileName", fileName);
        formData.append("FilePath", filePath);
        fetch(
            service.DOWNLOAD_FILE,
            {
                method: 'POST',
                body: formData
            })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    fileName,
                );
                // Append to html link element page
                document.body.appendChild(link);
                // Start download
                link.click();
                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });

        setLoading(false);
    };

    const handleDelete = (paymentId, expId) => {
        fetch(
            service.DELETE_EXPENDITURE_PAYMENT_DETAILS,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    PaymentId: paymentId,
                    ExpenditureId: expId
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.messageType === 'Success') {
                    // eslint-disable-next-line
                    alert(result.message);
                    getPendingExpenditures();
                    getPaymentDetails();
                }
                else if (result.messageType === 'Failed') {
                    // eslint-disable-next-line
                    alert(result.message);
                }
                else {
                    // eslint-disable-next-line
                    alert(result.message);
                }
            });
    }

    const submitHandler = e => {
        // eslint-disable-next-line  
        // console.log(SchemeIds);    // do not remove  
        e.preventDefault();
        if (validate()) {
            setLoading(true);
            fetch(
                service.ADD_UPDATE_EXPENDITURE_PAYMENT_DETAILS,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        ExpenditureId,
                        FundHeadId: FundHeadId.value,
                        PaymentDate: moment(paymentDate).format("MM/DD/YYYY"),
                        AddedBy: JSON.parse(sessionStorage.getItem('userData')).userId
                    })
                })
                .then(resp => resp.json())
                .then(respo => {
                    if (respo.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert("Data Saved Successfully");
                        setExpenditureId('');
                        getPendingExpenditures();
                        getPaymentDetails();
                    }
                    else if (respo.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(respo.message);
                    }
                    else {
                        // eslint-disable-next-line
                        alert(respo.message);
                    }
                });
            setLoading(false);
        }
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Expenditure Payment Details
                                </MDTypography>
                            </MDBox>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell colSpan="2" align="center">
                                                <Table aria-label="simple table">
                                                    <TableRow style={{ backgroundColor: 'lightgrey' }}>
                                                        <TableCell style={{ fontWeight: 'bold' }}>Select</TableCell>
                                                        <TableCell style={{ fontWeight: 'bold' }}>Scheme</TableCell>
                                                        <TableCell style={{ fontWeight: 'bold' }}>Pump</TableCell>
                                                        <TableCell style={{ fontWeight: 'bold' }}>Expenditure Component</TableCell>
                                                        <TableCell style={{ fontWeight: 'bold' }}>Expenditure SubComponent</TableCell>
                                                        <TableCell style={{ fontWeight: 'bold' }}>Expenditure Head</TableCell>
                                                        <TableCell style={{ fontWeight: 'bold' }}>Expenditure Date</TableCell>
                                                        <TableCell style={{ fontWeight: 'bold' }}>Amount</TableCell>
                                                        <TableCell style={{ fontWeight: 'bold' }}>Remarks</TableCell>
                                                        <TableCell style={{ fontWeight: 'bold' }}>File</TableCell>
                                                    </TableRow>
                                                    <TableBody>
                                                        {ExpenditureData.map((row) => (
                                                            <TableRow
                                                                key={row.expenditureId}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell>
                                                                    <Radio onChange={() => handleRadioChange(row.expenditureId)}
                                                                        value={row.expenditureId} name="radio-buttons"
                                                                        checked={ExpenditureId === row.expenditureId} />
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">{row.scheme}</TableCell>
                                                                <TableCell component="th" scope="row">{row.pump}</TableCell>
                                                                <TableCell component="th" scope="row">{row.expenditureComponent}</TableCell>
                                                                <TableCell component="th" scope="row">{row.expenditureSubComponent}</TableCell>
                                                                <TableCell component="th" scope="row">{row.expenditureHead}</TableCell>
                                                                <TableCell component="th" scope="row">{moment(row.expenditureDate).format('DD/MM/YYYY')}</TableCell>
                                                                <TableCell component="th" scope="row">{row.amount}</TableCell>
                                                                <TableCell component="th" scope="row">{row.remarks}</TableCell>
                                                                <TableCell component="th" scope="row"><MDButton onClick={() => handleDownload(row.fileName, row.filePath)}>{row.filename}</MDButton></TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".expenditureId"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align='left' style={{ fontSize: '12px' }}>
                                                Payment Date
                                                <DatePicker
                                                    id="paymentDate"
                                                    selected={paymentDate}
                                                    onChange={date => setPaymentDate(date)}
                                                    dateFormat="MM/dd/yyyy"
                                                    tabIndex={0}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".paymentDate"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <Select
                                                    name="Fund Head"
                                                    options={FundHeadData} placeholder="Select Fund Head"
                                                    value={FundHeadId}
                                                    onChange={FundHeadChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".FundHeadId"]}</div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                <MDBox mt={4} mb={1} textAlign="center">
                                    {loading ? <Oval
                                        heigth="20"
                                        width="20"
                                        color='grey'
                                        ariaLabel='loading'
                                    /> :
                                        <div>
                                            <MDButton color="info" onClick={submitHandler}>
                                                SAVE
                                            </MDButton>&nbsp;&nbsp;&nbsp;&nbsp;
                                            <MDButton color="info" onClick={clearHandler}>
                                                CLEAR
                                            </MDButton>
                                        </div>
                                    }
                                    {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                </MDBox>
                            </Card>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                {loading ? <Oval
                                    heigth="100"
                                    width="100"
                                    color='grey'
                                    ariaLabel='loading'
                                /> :
                                    <TableContainer component={Paper} style={{ width: '95%', alignSelf: 'center', }}>
                                        <Table aria-label="simple table">
                                            <TableRow style={{ backgroundColor: 'lightgrey' }}>
                                                <TableCell style={{ fontWeight: 'bold' }}>Scheme</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Pump</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Expenditure Component</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Expenditure SubComponent</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Expenditure Head</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Fund Head</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Payment Date</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Action</TableCell>
                                            </TableRow>
                                            <TableBody>
                                                {PaymentData.map((row) => (
                                                    <TableRow
                                                        key={row.expenditureId}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">{row.scheme}</TableCell>
                                                        <TableCell component="th" scope="row">{row.pump}</TableCell>
                                                        <TableCell component="th" scope="row">{row.expenditureComponent}</TableCell>
                                                        <TableCell component="th" scope="row">{row.expenditureSubComponent}</TableCell>
                                                        <TableCell component="th" scope="row">{row.expenditureHead}</TableCell>
                                                        <TableCell component="th" scope="row">{row.funHead}</TableCell>
                                                        <TableCell component="th" scope="row">{moment(row.paymentDate).format('DD/MM/YYYY')}</TableCell>
                                                        <TableCell><MDButton onClick={() => handleDelete(row.paymentId, row.expenditureId)}>Delete</MDButton></TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                }
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
                <Footer />
            </div>
        </DashboardLayout>
    );
}
export default ExpenditurePaymentDetails;