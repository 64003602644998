// import { useNavigate } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import MDButton from 'components/MDButton';
import MDInput from "components/MDInput";
import { Oval } from 'react-loader-spinner';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Select from 'react-select';
import * as service from '../../../services/service';

function Pumps() {
    const [state, setState] = useState({ pump: '', hp: '', discharge: '', totalhead: '', rpm: '', stages: '', kw: '' });
    const [CEData, setCEData] = useState([]);
    const [SEData, setSEData] = useState([]);
    const [MechanicalDivisionData, setMechanicalDivisionData] = useState([]);
    const [MechanicalSubDivisionData, setMechanicalSubDivisionData] = useState([]);
    const [CEId, setCEId] = useState('');
    const [SEId, setSEId] = useState('');
    const [MechanicalDivisionId, setMechanicalDivisionId] = useState('');
    const [MechanicalSubDivisionId, setMechanicalSubDivisionId] = useState('');
    const [SchemeData, setSchemeData] = useState([]);
    const [SchemeId, setSchemeId] = useState('');
    const [PumpHouseData, setPumpHouseData] = useState([]);
    const [PumpHouseId, setPumpHouseId] = useState('');
    const [PumpTypeData, setPumpTypeData] = useState([]);
    const [PumpTypeId, setPumpTypeId] = useState('');
    const [status, setStatus] = useState('');
    const statusData = [{ label: "Active", value: "Active" }, { label: "Inactive", value: "Inactive" }];
    const [data, setData] = useState([]);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    // const navigate = useNavigate();
    const getCEs = () => {
        fetch(
            service.CE_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.ceId, label: item.ce }));
                setCEData(data2);
            });
    };

    const getSEs = (id) => {
        fetch(
            service.GET_SE_BY_CE_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CEId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.seId, label: item.se }));
                setSEData(data2);
            });
    };
    const getMechanicalDivisions = (id) => {
        fetch(
            service.GET_MECHANICAL_DIVISION_BY_SE_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    SEId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.mechanicalDivisionId, label: item.mechanicalDivision }));
                setMechanicalDivisionData(data2);
            });
    };

    const getMechanicalSubDivisions = (id) => {
        fetch(
            service.GET_MECHANICAL_SUB_DIVISION_BY_MECH_DIV_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    MechanicalDivisionId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.mechanicalSubDivisionId, label: item.mechanicalSubDivision }));
                setMechanicalSubDivisionData(data2);
            });
    };

    const getSchemes = () => {
        const errs = {};
        fetch(
            service.GET_SCHEME_ALLOCATION_TO_MECHSUBDIV_BY_SUBDIVID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    MechanicalSubDivisionId: JSON.parse(sessionStorage.getItem('userData')).mechanicalSubDivisionId
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.schemeId, label: item.scheme }));
                setSchemeData(data2);
            })
            .catch(err => {
                if (err) {
                    errs[".invalid"] = err;
                    setErrors(err);
                };
            });
    };

    const getPumpHouse = () => {
        fetch(
            service.PUMP_HOUSE_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.pumpHouseId, label: item.pumpHouse }));
                setPumpHouseData(data2);
            });
    };
    const getPumpType = () => {
        fetch(
            service.PUMP_TYPE_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.pumpTypeId, label: item.pumpType }));
                setPumpTypeData(data2);
            });
    };
    const getDetails = (id) => {
        const errs = {};
        fetch(
            service.PUMP_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    SchemeId: id
                })
            })
            .then(res => res.json())
            .then(result => {
                setData(result);
            })
            .catch(err => {
                if (err) {
                    errs[".invalid"] = err;
                    setErrors(err);
                };
            });
    };

    const getDetailsById = (id) => {
        setLoading(true);
        const errs = {};
        fetch(
            service.GET_PUMP_BY_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    PumpId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.message.messageType === 'Success') {
                    setState({
                        ...state, pump: result.pump, hp: result.hp, discharge: result.discharge, totalhead: result.totalHead,
                        rpm: result.rpm, stages: result.stages, kw: result.kw
                    });
                    const ac = ({ value: result.status, label: result.status });
                    setStatus(ac);
                    const ph = ({ value: result.pumpHouseId, label: result.pumpHouse });
                    setPumpHouseId(ph);
                    const pt = ({ value: result.pumpTypeId, label: result.pumpType });
                    setPumpTypeId(pt);
                }
                else {
                    errs[".invalid"] = result.message.message;
                    setErrors(errs);
                }
            });
        setLoading(false);
    };

    const handleEdit = (id) => {
        getDetailsById(id);
        localStorage.setItem("ID", JSON.stringify(id));
    }

    useEffect(() => {
        setLoading(true);
        getCEs();
        const cc = ({ label: JSON.parse(sessionStorage.getItem('userData')).ce, value: JSON.parse(sessionStorage.getItem('userData')).ceId });
        setCEId(cc);
        getSEs(cc.value);
        const sc = ({ label: JSON.parse(sessionStorage.getItem('userData')).se, value: JSON.parse(sessionStorage.getItem('userData')).seId });
        setSEId(sc);
        getMechanicalDivisions(sc.value);
        const dv = ({ label: JSON.parse(sessionStorage.getItem('userData')).mechanicalDivision, value: JSON.parse(sessionStorage.getItem('userData')).mechanicalDivisionId });
        setMechanicalDivisionId(dv);
        getMechanicalSubDivisions(dv.value);
        const sb = ({ label: JSON.parse(sessionStorage.getItem('userData')).mechanicalSubDivision, value: JSON.parse(sessionStorage.getItem('userData')).mechanicalSubDivisionId });
        setMechanicalSubDivisionId(sb);
        getSchemes();
        getPumpHouse();
        getPumpType();
        const st = ({ label: "Active", value: "Active" });
        setStatus(st);
        setLoading(false);
    }, []);

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!MechanicalSubDivisionId) {
            isValid = false;
            errs[".MechanicalSubDivisionId"] = "Please select mechanical sub division";
        }
        if (!state.pump) {
            isValid = false;
            errs[".pump"] = "Please enter pump";
        }
        if (!PumpHouseId) {
            isValid = false;
            errs[".PumpHouseId"] = "Please select Pump House";
        }
        if (!PumpTypeId) {
            isValid = false;
            errs[".PumpTypeId"] = "Please select Pump Type";
        }
        if (!SchemeId) {
            isValid = false;
            errs[".SchemeId"] = "Please select Scheme";
        }
        setErrors(errs);
        return isValid;
    }
    const changeHandler = (event) => {        
        if (event.target.name !== 'pump') {
            if (Number.isNaN(parseFloat(event.target.value))) {
                // eslint-disable-next-line
                alert("Please enter numbers only");
                event.target.value = ""; // eslint-disable-line no-param-reassign
            }
        }
        setState({ ...state, [event.target.name]: event.target.value });
    };
    const CEChangeHandler = (ev) => {
        setCEId(ev);
        getSEs(ev.value);
    }
    const SEChangeHandler = (ev) => {
        setSEId(ev);
        setMechanicalDivisionData([]);
        getMechanicalDivisions(ev.value);
    }
    const MechanicalDivisionChangeHandler = (ev) => {
        setMechanicalDivisionId(ev);
        setMechanicalSubDivisionData([]);
        getMechanicalSubDivisions(ev.value);
    }
    const MechanicalSubDivisionChangeHandler = (ev) => {
        setMechanicalSubDivisionId(ev);
        getSchemes();
    }
    const SchemeChangeHandler = (ev) => {
        setSchemeId(ev);
        getDetails(ev.value);        
    }
    const PumpHouseChangeHandler = (ev) => {
        setPumpHouseId(ev);
    }
    const PumpTypeChangeHandler = (ev) => {
        setPumpTypeId(ev);
    }
    const StatusChangeHandler = (ev) => {
        setStatus(ev);
    }
    const clearHandler = () => {
        localStorage.setItem('ID', null);
        setState({ ...state, pump: '', hp: '', discharge: '', totalhead: '', rpm: '', stages: '', kw: ''  });
        const ph = ({ value: null, label: "Select Pump House" });
        setPumpHouseId(ph);
        const pt = ({ value: null, label: "Select Pump Type" });
        setPumpTypeId(pt);
        setData([]);
    }

    const submitHandler = e => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        if (validate()) {
            fetch(
                service.ADD_UPDATE_PUMP,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        PumpId: JSON.parse(localStorage.getItem('ID')) !== null ? JSON.parse(localStorage.getItem('ID')) : 0,
                        Pump: state.pump,
                        SchemeId: SchemeId.value,
                        PumpHouseId: PumpHouseId.value,
                        PumpTypeId: PumpTypeId.value,
                        HP: state.hp,
                        Discharge: state.discharge,
                        TotalHead: state.totalhead,
                        RPM: state.rpm,
                        Stages: state.stages,
                        KW: state.kw,
                        Status: status.value,
                        AddedBy: JSON.parse(sessionStorage.getItem('userData')).userId,
                        UpdatedBy: JSON.parse(sessionStorage.getItem('userData')).userId,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                        localStorage.setItem('ID', null);
                        // window.location.reload();
                        setState({ ...state, pump: '', hp: '', discharge: '', totalhead: '', rpm: '', stages: '', kw: ''  });
                        getDetails(SchemeId.value);
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Pump Details
                                </MDTypography>
                            </MDBox>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                <Select
                                                    name="CE"
                                                    options={CEData} placeholder="Select CE"
                                                    value={CEId}
                                                    onChange={CEChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                    isDisabled
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".ceId"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <Select
                                                    name="SE"
                                                    options={SEData} placeholder="Select SE"
                                                    value={SEId}
                                                    onChange={SEChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                    isDisabled
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".seId"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Select
                                                    name="Mechanical Division"
                                                    options={MechanicalDivisionData} placeholder="Select Mechanical Division"
                                                    value={MechanicalDivisionId}
                                                    onChange={MechanicalDivisionChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                    isDisabled
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".MechanicalDivisionId"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <Select
                                                    name="Mechanical Sub Division"
                                                    options={MechanicalSubDivisionData} placeholder="Select Mechanical Sub Division"
                                                    value={MechanicalSubDivisionId}
                                                    onChange={MechanicalSubDivisionChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                    isDisabled
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".MechanicalSubDivisionId"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Select
                                                    name="Scheme"
                                                    options={SchemeData} placeholder="Select Scheme"
                                                    value={SchemeId}
                                                    onChange={SchemeChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".SchemeId"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <MDInput label="Pump" fullWidth value={state.pump || ''}
                                                    name="pump"
                                                    onChange={changeHandler}
                                                    id="pump"
                                                    required />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".pump"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <MDInput label="HP" fullWidth value={state.hp || ''}
                                                    name="hp"
                                                    onChange={changeHandler}
                                                    id="hp"                                                    
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".hp"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <MDInput label="Discharge" fullWidth value={state.discharge || ''}
                                                    name="discharge"
                                                    onChange={changeHandler}
                                                    id="discharge"                                                    
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".discharge"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <MDInput label="Total Head" fullWidth value={state.totalhead || ''}
                                                    name="totalhead"
                                                    onChange={changeHandler}
                                                    id="totalhead"                                                    
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".totalhead"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <MDInput label="RPM" fullWidth value={state.rpm || ''}
                                                    name="rpm"
                                                    onChange={changeHandler}
                                                    id="rpm"                                                    
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".rpm"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <MDInput label="Stages" fullWidth value={state.stages || ''}
                                                    name="stages"
                                                    onChange={changeHandler}
                                                    id="stages"                                                    
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".stages"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <MDInput label="KW" fullWidth value={state.kw || ''}
                                                    name="kw"
                                                    onChange={changeHandler}
                                                    id="kw"                                                    
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".kw"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Select
                                                    name="Pump House"
                                                    options={PumpHouseData} placeholder="Select Pump House"
                                                    value={PumpHouseId}
                                                    onChange={PumpHouseChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".PumpHouseId"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <Select
                                                    name="Pump Type"
                                                    options={PumpTypeData} placeholder="Select Pump Type"
                                                    value={PumpTypeId}
                                                    onChange={PumpTypeChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".PumpTypeId"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Select
                                                    name="Status"
                                                    options={statusData}
                                                    value={status}
                                                    onChange={StatusChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".status"]}</div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                <MDBox mt={4} mb={1} textAlign="center">
                                    {loading ? <Oval
                                        heigth="20"
                                        width="20"
                                        color='grey'
                                        ariaLabel='loading'
                                    /> :
                                        <div>
                                            <MDButton color="info" onClick={submitHandler}>
                                                SAVE
                                            </MDButton>&nbsp;&nbsp;&nbsp;&nbsp;
                                            <MDButton color="info" onClick={clearHandler}>
                                                CLEAR
                                            </MDButton>
                                        </div>
                                    }
                                    {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                </MDBox>

                            </Card>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                {loading ? <Oval
                                    heigth="100"
                                    width="100"
                                    color='grey'
                                    ariaLabel='loading'
                                /> :
                                    <TableContainer component={Paper} style={{ width: '80%', alignSelf: 'center', }}>
                                        <Table aria-label="simple table">
                                            <TableRow style={{ backgroundColor: 'lightgrey' }}>
                                                <TableCell style={{ fontWeight: 'bold' }}>Pump</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Pump House</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Pump Type</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Scheme</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>HP</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Discharge</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Status</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Action</TableCell>
                                            </TableRow>
                                            <TableBody>
                                                {data.map((row) => (
                                                    <TableRow
                                                        key={row.pumpId}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">{row.pump}</TableCell>
                                                        <TableCell component="th" scope="row">{row.pumpHouse}</TableCell>
                                                        <TableCell component="th" scope="row">{row.pumpType}</TableCell>
                                                        <TableCell component="th" scope="row">{row.scheme}</TableCell>
                                                        <TableCell component="th" scope="row">{row.hp}</TableCell>
                                                        <TableCell component="th" scope="row">{row.discharge}</TableCell>
                                                        <TableCell component="th" scope="row">{row.status}</TableCell>
                                                        <TableCell><MDButton onClick={() => handleEdit(row.pumpId)}>Edit</MDButton></TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                }
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox >
            <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
                <Footer />
            </div>
        </DashboardLayout >
    );
}
export default Pumps;