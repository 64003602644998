// import { useNavigate } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import MDButton from 'components/MDButton';
import MDInput from "components/MDInput";
import { Oval } from 'react-loader-spinner';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Select from 'react-select';
import { TextField } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as moment from 'moment';
import * as service from '../../services/service'

function ExpenditureDetails() {
    const [state, setState] = useState({ amount: '', remarks: '' });
    const [CEData, setCEData] = useState([]);
    const [SEData, setSEData] = useState([]);
    const [MechanicalDivisionData, setMechanicalDivisionData] = useState([]);
    const [MechanicalSubDivisionData, setMechanicalSubDivisionData] = useState([]);
    const [CEId, setCEId] = useState('');
    const [SEId, setSEId] = useState('');
    const [MechanicalDivisionId, setMechanicalDivisionId] = useState('');
    const [MechanicalSubDivisionId, setMechanicalSubDivisionId] = useState('');
    const [SchemeData, setSchemeData] = useState([]);
    const [SchemeId, setSchemeId] = useState('');
    const [PumpData, setPumpData] = useState([]);
    const [PumpId, setPumpId] = useState('');
    const [ComponentData, setComponentData] = useState([]);
    const [SubComponentData, setSubComponentData] = useState([]);
    const [expenditureHeadData, setExpenditureHeadData] = useState([]);
    const [expenditureHeadId, setExpenditureHeadId] = useState('');
    const [ComponentId, setComponentId] = useState('');
    const [SubComponentId, setSubComponentId] = useState('');
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [expenditureDate, setExpenditureDate] = useState(new Date());
    const [file, setFile] = useState('');
    const [data, setData] = useState([]);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    // const navigate = useNavigate();
    const getCEs = () => {
        fetch(
            service.CE_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.ceId, label: item.ce }));
                setCEData(data2);
            });
    };

    const getSEs = (id) => {
        fetch(
            service.GET_SE_BY_CE_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CEId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.seId, label: item.se }));
                setSEData(data2);
            });
    };
    const getMechanicalDivisions = (id) => {
        fetch(
            service.GET_MECHANICAL_DIVISION_BY_SE_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    SEId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.mechanicalDivisionId, label: item.mechanicalDivision }));
                setMechanicalDivisionData(data2);
            });
    };

    const getMechanicalSubDivisions = (id) => {
        fetch(
            service.GET_MECHANICAL_SUB_DIVISION_BY_MECH_DIV_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    MechanicalDivisionId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.mechanicalSubDivisionId, label: item.mechanicalSubDivision }));
                setMechanicalSubDivisionData(data2);
            });
    };

    const getSchemes = () => {
        const errs = {};
        fetch(
            service.GET_SCHEME_ALLOCATION_TO_MECHSUBDIV_BY_SUBDIVID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    MechanicalSubDivisionId: JSON.parse(sessionStorage.getItem('userData')).mechanicalSubDivisionId
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.schemeId, label: item.scheme }));
                setSchemeData(data2);
            })
            .catch(err => {
                if (err) {
                    errs[".invalid"] = err;
                    setErrors(err);
                };
            });
    };

    const getPumps = (id) => {
        const errs = {};
        fetch(
            service.PUMP_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    SchemeId: id
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.pumpId, label: item.pump }));
                setPumpData(data2);
            })
            .catch(err => {
                if (err) {
                    errs[".invalid"] = err;
                    setErrors(err);
                };
            });
    };

    const getExpenditureComponents = () => {
        fetch(
            service.EXPENDITURE_COMPONENT_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.expenditureComponentId, label: item.expenditureComponent }));
                setComponentData(data2);
            });
    };

    const getExpenditureSubComponents = (id) => {
        fetch(
            service.GET_EXPENDITURE_SUB_COMPONENT_BY_COMPONENT_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ExpenditureComponentId: id
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.expenditureSubComponentId, label: item.expenditureSubComponent }));
                setSubComponentData(data2);
            });
    };

    const getExpenditureHeads = () => {
        fetch(
            service.EXPENDITURE_HEAD_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.expenditureHeadId, label: item.expenditureHead }));
                setExpenditureHeadData(data2);
            });
    };

    const getDetails = (shId, pmId) => {
        const errs = {};
        fetch(
            service.EXPENDITURE_DETAILS_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    SchemeId: shId,
                    PumpId: pmId
                })
            })
            .then(res => res.json())
            .then(result => {
                setData(result);
            })
            .catch(err => {
                if (err) {
                    errs[".invalid"] = err;
                    setErrors(err);
                };
            });
    };

    const getDetailsById = (id) => {
        setLoading(true);
        const errs = {};
        fetch(
            service.GET_EXPENDITURE_DETAILS_BY_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ExpenditureId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.message.messageType === 'Success') {
                    setState({
                        ...state, amount: result.amount, remarks: result.remarks
                    });
                    const com = ({ value: result.expenditureComponentId, label: result.expenditureComponent });
                    setComponentId(com);
                    const sub = ({ value: result.expenditureSubComponentId, label: result.expenditureSubComponent });
                    setSubComponentId(sub);
                    const hd = ({ value: result.expenditureHeadId, label: result.expenditureHead });
                    setExpenditureHeadId(hd);
                    setFromDate(new Date(result.expenditureFromDate));
                    setToDate(new Date(result.expenditureToDate));
                    setExpenditureDate(new Date(result.expenditureDate));
                }
                else {
                    errs[".invalid"] = result.message.message;
                    setErrors(errs);
                }
            });
        setLoading(false);
    };

    const handleEdit = (id) => {
        localStorage.setItem("ID", JSON.stringify(id));
        getDetailsById(id);
    }

    useEffect(() => {
        setLoading(true);
        getCEs();
        const cc = ({ label: JSON.parse(sessionStorage.getItem('userData')).ce, value: JSON.parse(sessionStorage.getItem('userData')).ceId });
        setCEId(cc);
        getSEs(cc.value);
        const sc = ({ label: JSON.parse(sessionStorage.getItem('userData')).se, value: JSON.parse(sessionStorage.getItem('userData')).seId });
        setSEId(sc);
        getMechanicalDivisions(sc.value);
        const dv = ({ label: JSON.parse(sessionStorage.getItem('userData')).mechanicalDivision, value: JSON.parse(sessionStorage.getItem('userData')).mechanicalDivisionId });
        setMechanicalDivisionId(dv);
        getMechanicalSubDivisions(dv.value);
        const sb = ({ label: JSON.parse(sessionStorage.getItem('userData')).mechanicalSubDivision, value: JSON.parse(sessionStorage.getItem('userData')).mechanicalSubDivisionId });
        setMechanicalSubDivisionId(sb);
        getSchemes();
        getExpenditureComponents();
        getExpenditureHeads();
        setLoading(false);
    }, []);

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!MechanicalSubDivisionId) {
            isValid = false;
            errs[".MechanicalSubDivisionId"] = "Please select mechanical sub division";
        }
        if (!SchemeId) {
            isValid = false;
            errs[".SchemeId"] = "Please select Scheme";
        }
        if (!ComponentId) {
            isValid = false;
            errs[".ComponentId"] = "Please select Expenditure Component";
        }
        if (!SubComponentId) {
            isValid = false;
            errs[".SubComponentId"] = "Please select Expenditure Sub Component";
        }
        if (ComponentId.label === 'Pump Specific Components' && !PumpId) {
            isValid = false;
            errs[".PumpId"] = "Please select pump";
        }
        if (state.amount === 0 || !state.amount) {
            isValid = false;
            errs[".amount"] = "Please enter amount";
        }
        if (!expenditureHeadId) {
            isValid = false;
            errs[".expenditureHeadId"] = "Please select Expenditure Head";
        }
        setErrors(errs);
        return isValid;
    }
    const changeHandler = (event) => {
        if (event.target.name === 'amount') {
            if (Number.isNaN(parseFloat(event.target.value))) {
                // eslint-disable-next-line
                alert("Please enter numbers only");
                event.target.value = ""; // eslint-disable-line no-param-reassign
            }
        }
        setState({ ...state, [event.target.name]: event.target.value });
    };
    const CEChangeHandler = (ev) => {
        setCEId(ev);
        getSEs(ev.value);
    }
    const SEChangeHandler = (ev) => {
        setSEId(ev);
        setMechanicalDivisionData([]);
        getMechanicalDivisions(ev.value);
    }
    const MechanicalDivisionChangeHandler = (ev) => {
        setMechanicalDivisionId(ev);
        setMechanicalSubDivisionData([]);
        getMechanicalSubDivisions(ev.value);
    }
    const MechanicalSubDivisionChangeHandler = (ev) => {
        setMechanicalSubDivisionId(ev);
        getSchemes();
    }
    const SchemeChangeHandler = (ev) => {
        setSchemeId(ev);
        getPumps(ev.value);
        getDetails(ev.value, 0);
    }
    const PumpChangeHandler = (ev) => {
        setPumpId(ev);
        getDetails(SchemeId.value, ev.value);
    }
    const ComponentChangeHandler = (ev) => {
        setComponentId(ev);
        setSubComponentData([]);
        setSubComponentId('');
        getExpenditureSubComponents(ev.value);
        setSchemeId('');
        setPumpId('');
        setData([]);
    }
    const SubComponentChangeHandler = (ev) => {
        setSubComponentId(ev);        
    }
    const ExpenditureHeadChangeHandler = (ev) => {
        setExpenditureHeadId(ev);
    }
    const clearHandler = () => {
        localStorage.setItem('ID', null);
        setState({ ...state, amount: '', remarks: '' });
        const com = ({ value: null, label: "Select Expenditure Component" });
        setComponentId(com);
        const sub = ({ value: null, label: "Select Expenditure Sub Component" });
        setSubComponentId(sub);
        const hd = ({ value: null, label: "Select Expenditure Head" });
        setExpenditureHeadId(hd);
        setFromDate(new Date());
        setToDate(new Date());
        setExpenditureDate(new Date());
        const sc = ({ value: null, label: "Select Scheme" });
        setSchemeId(sc);
        const pm = ({ value: null, label: "Select Pump" });
        setPumpId(pm);
        setData([]);
    }

    const handleDownload = (fileName, filePath) => {
        setLoading(true);
        const formData = new FormData();
        formData.append("FileName", fileName);
        formData.append("FilePath", filePath);
        fetch(
            service.DOWNLOAD_FILE,
            {
                method: 'POST',
                body: formData
            })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    fileName,
                );
                // Append to html link element page
                document.body.appendChild(link);
                // Start download
                link.click();
                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });

        setLoading(false);
    };

    const submitHandler = e => {
        e.preventDefault();
        setLoading(true);
        if (validate()) {
            const formData = new FormData();
            formData.append("ExpenditureId", JSON.parse(localStorage.getItem('ID')) !== null ? JSON.parse(localStorage.getItem('ID')) : 0,);
            formData.append("MechanicalSubDivisionId", JSON.parse(sessionStorage.getItem('userData')).mechanicalSubDivisionId,);
            formData.append("SchemeId", SchemeId.value);
            formData.append("PumpId", ComponentId.label === 'Pump Specific Components' && PumpId ? PumpId.value : 0);
            formData.append("ExpenditureComponentId", ComponentId.value);
            formData.append("ExpenditureSubComponentId", SubComponentId.value);
            formData.append("ExpenditureHeadId", expenditureHeadId.value);
            formData.append("ExpenditureFromDate", (new Date(fromDate)).toUTCString());
            formData.append("ExpenditureToDate", (new Date(toDate)).toUTCString());
            formData.append("ExpenditureDate", (new Date(expenditureDate)).toUTCString());
            formData.append("Remarks", state.remarks);
            formData.append("Amount", state.amount);
            formData.append("File", file);
            formData.append("AddedBy", JSON.parse(sessionStorage.getItem('userData')).userId);
            formData.append("UpdatedBy", JSON.parse(sessionStorage.getItem('userData')).userId);
            fetch(
                service.ADD_UPDATE_EXPENDITURE_DETAILS,
                {
                    method: 'POST',
                    body: formData
                })
                .then(resp => resp.json())
                .then(respo => {
                    if (respo.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(respo.message);
                        localStorage.setItem('ID', null);
                        setFile('');
                        setState({ ...state, amount: '', remarks: '' });
                        getDetails(SchemeId.value, ComponentId.label === 'Pump Specific Components' && PumpId ? PumpId.value : 0);
                    }
                    else if (respo.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(respo.message);
                    }
                    else {
                        // eslint-disable-next-line
                        alert(respo.message);
                    }
                });
        }
        setLoading(false);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Expenditure Details
                                </MDTypography>
                            </MDBox>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                <Select
                                                    name="Expenditure Component"
                                                    options={ComponentData} placeholder="Select Expenditure Component"
                                                    value={ComponentId}
                                                    onChange={ComponentChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".ComponentId"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <Select
                                                    name="Expenditure Sub Component"
                                                    options={SubComponentData} placeholder="Select Expenditure Sub Component"
                                                    value={SubComponentId}
                                                    onChange={SubComponentChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".SubComponentId"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell width="50%">
                                                <Select
                                                    name="CE"
                                                    options={CEData} placeholder="Select CE"
                                                    value={CEId}
                                                    onChange={CEChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                    isDisabled
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".ceId"]}</div>
                                            </TableCell>
                                            <TableCell width="50%">
                                                <Select
                                                    name="SE"
                                                    options={SEData} placeholder="Select SE"
                                                    value={SEId}
                                                    onChange={SEChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                    isDisabled
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".seId"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Select
                                                    name="Mechanical Division"
                                                    options={MechanicalDivisionData} placeholder="Select Mechanical Division"
                                                    value={MechanicalDivisionId}
                                                    onChange={MechanicalDivisionChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                    isDisabled
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".MechanicalDivisionId"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <Select
                                                    name="Mechanical Sub Division"
                                                    options={MechanicalSubDivisionData} placeholder="Select Mechanical Sub Division"
                                                    value={MechanicalSubDivisionId}
                                                    onChange={MechanicalSubDivisionChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                    isDisabled
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".MechanicalSubDivisionId"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Select
                                                    name="Scheme"
                                                    options={SchemeData} placeholder="Select Scheme"
                                                    value={SchemeId}
                                                    onChange={SchemeChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".SchemeId"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <Select
                                                    name="Pump"
                                                    options={PumpData} placeholder="Select Pump"
                                                    value={PumpId}
                                                    onChange={PumpChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                    isDisabled={ComponentId.label === 'Scheme Specific Components'}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".PumpId"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                From Date
                                                <DatePicker
                                                    id="fromDate"
                                                    selected={fromDate}
                                                    onChange={date => setFromDate(date)}
                                                    dateFormat="MM/dd/yyyy"
                                                    tabIndex={0}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".fromDate"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                To Date
                                                <DatePicker
                                                    id="toDate"
                                                    selected={toDate}
                                                    onChange={date => setToDate(date)}
                                                    dateFormat="MM/dd/yyyy"
                                                    tabIndex={0}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".toDate"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                Expenditure Date
                                                <DatePicker
                                                    id="expenditureDate"
                                                    selected={expenditureDate}
                                                    onChange={date => setExpenditureDate(date)}
                                                    dateFormat="MM/dd/yyyy"
                                                    tabIndex={0}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".expenditureDate"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <Select
                                                    name="Expenditure Head"
                                                    options={expenditureHeadData} placeholder="Select Expenditure Head"
                                                    value={expenditureHeadId}
                                                    onChange={ExpenditureHeadChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".expenditureHeadId"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <MDInput label="Amount" fullWidth value={state.amount || ''}
                                                    name="amount"
                                                    onChange={changeHandler}
                                                    id="amount"
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".amount"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <MDInput label="Remarks" fullWidth value={state.remarks || ''}
                                                    name="remarks"
                                                    onChange={changeHandler}
                                                    id="remarks"
                                                    required
                                                    multiline />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".remarks"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                File
                                                <br />
                                                <TextField
                                                    id="file"
                                                    type="file"
                                                    onChange={ev => setFile(ev.target.files[0])}
                                                    style={{ width: 200 }}
                                                    required
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".file"]}</div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                <MDBox mt={4} mb={1} textAlign="center">
                                    {loading ? <Oval
                                        heigth="20"
                                        width="20"
                                        color='grey'
                                        ariaLabel='loading'
                                    /> :
                                        <div>
                                            <MDButton color="info" onClick={submitHandler}>
                                                SAVE
                                            </MDButton>&nbsp;&nbsp;&nbsp;&nbsp;
                                            <MDButton color="info" onClick={clearHandler}>
                                                CLEAR
                                            </MDButton>
                                        </div>
                                    }
                                    {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                </MDBox>

                            </Card>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                {loading ? <Oval
                                    heigth="100"
                                    width="100"
                                    color='grey'
                                    ariaLabel='loading'
                                /> :
                                    <TableContainer component={Paper} style={{ width: '95%', alignSelf: 'center', }}>
                                        <Table aria-label="simple table">
                                            <TableRow style={{ backgroundColor: 'lightgrey' }}>
                                                <TableCell style={{ fontWeight: 'bold' }}>Scheme</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Pump</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Expenditure Component</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Expenditure SubComponent</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Expenditure Head</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Expenditure Date</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Amount</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Remarks</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>File</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Action</TableCell>
                                            </TableRow>
                                            <TableBody>
                                                {data.map((row) => (
                                                    <TableRow
                                                        key={row.expenditureId}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">{row.scheme}</TableCell>
                                                        <TableCell component="th" scope="row">{row.pump}</TableCell>
                                                        <TableCell component="th" scope="row">{row.expenditureComponent}</TableCell>
                                                        <TableCell component="th" scope="row">{row.expenditureSubComponent}</TableCell>
                                                        <TableCell component="th" scope="row">{row.expenditureHead}</TableCell>
                                                        <TableCell component="th" scope="row">{moment(row.expenditureDate).format('DD/MM/YYYY')}</TableCell>
                                                        <TableCell component="th" scope="row">{row.amount}</TableCell>
                                                        <TableCell component="th" scope="row">{row.remarks}</TableCell>
                                                        <TableCell component="th" scope="row"><MDButton onClick={() => handleDownload(row.fileName, row.filePath)}>{row.fileName}</MDButton></TableCell>
                                                        <TableCell><MDButton onClick={() => handleEdit(row.expenditureId)}>Edit</MDButton></TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                }
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox >
            <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
                <Footer />
            </div>
        </DashboardLayout >
    );
}
export default ExpenditureDetails;