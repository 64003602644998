// import { useNavigate } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import MDButton from 'components/MDButton';
import MDInput from "components/MDInput";
import { Oval } from 'react-loader-spinner';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as moment from 'moment';
import { Checkbox } from '@mui/material';
import * as service from '../../../services/service';

function SchemeAllocationToMechanicalDivision() {
    const [state, setState] = useState({ remarks: '' });
    const [CEData, setCEData] = useState([]);
    const [SEData, setSEData] = useState([]);
    const [MechanicalDivisionData, setMechanicalDivisionData] = useState([]);
    const [CEId, setCEId] = useState('');
    const [SEId, setSEId] = useState('');
    const [MechanicalDivisionId, setMechanicalDivisionId] = useState('');     
    const [allocationDate, setAllocationDate] = useState(new Date());
    const [SchemeData, setSchemeData] = useState([]);
    const [allocationData, setAllocationData] = useState([]);
    const [SchemeIds, setSchemeIds] = useState([]);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

    const getCEs = () => {
        fetch(
            service.CE_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.ceId, label: item.ce }));
                setCEData(data2);
            });
    };

    const getSEs = (id) => {
        fetch(
            service.GET_SE_BY_CE_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CEId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.seId, label: item.se }));
                setSEData(data2);
            });
    };
    const getMechanicalDivisions = (id) => {
        fetch(
            service.GET_MECHANICAL_DIVISION_BY_SE_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    SEId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.mechanicalDivisionId, label: item.mechanicalDivision }));
                setMechanicalDivisionData(data2);
            });
    };   
    const getSchemes = () => {
        const errs = {};
        fetch(
            service.GET_SCHEME_NOT_ALLOCATED_TO_MECHDIV,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({                   
                    SEId: JSON.parse(sessionStorage.getItem('userData')).seId
                })
            })
            .then(res => res.json())
            .then(result => {
                setSchemeData(result);
            })
            .catch(err => {
                if (err) {
                    errs[".invalid"] = err;
                    setErrors(err);
                };
            });
    };

   
    const getAllocationDetails = () => {
        const errs = {};
        fetch(
            service.GET_SCHEME_ALLOCATED_TO_MECHDIV,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({                   
                    SEId: JSON.parse(sessionStorage.getItem('userData')).seId
                })
            })
            .then(res => res.json())
            .then(result => {
                setAllocationData(result);
            })
            .catch(err => {
                if (err) {
                    errs[".invalid"] = err;
                    setErrors(err);
                };
            });
    };

    useEffect(() => {
        setLoading(true);        
        getCEs();
        const cc = ({label: JSON.parse(sessionStorage.getItem('userData')).ce, value:JSON.parse(sessionStorage.getItem('userData')).ceId});
        setCEId(cc);
        getSEs(cc.value);
        const sc = ({label: JSON.parse(sessionStorage.getItem('userData')).se, value:JSON.parse(sessionStorage.getItem('userData')).seId});
        setSEId(sc);
        getMechanicalDivisions(sc.value);
        getSchemes();
        getAllocationDetails();
        setLoading(false);
    }, []);

    const validate = () => {
        let isValid = true;
        const errs = {};        
        if (!SEId) {
            isValid = false;
            errs[".seId"] = "Please select SE";
        }
        if (!MechanicalDivisionId) {
            isValid = false;
            errs[".MechanicalDivisionId"] = "Please select Mechanical Division";
        }
        if (!allocationDate) {
            isValid = false;
            errs[".allocationDate"] = "Please select date";
        }
        if (SchemeIds.length === 0) {
            isValid = false;
            errs[".SchemeIds"] = "Please select scheme";
        }
        setErrors(errs);
        return isValid;
    }
    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const CEChangeHandler = (ev) => {
        setCEId(ev);
        getSEs(ev.value);
    }
    const SEChangeHandler = (ev) => {
        setSEId(ev);
        setMechanicalDivisionData([]); 
        getMechanicalDivisions(ev.value);      
    }
    const MechanicalDivisionChangeHandler = (ev) => {
        setMechanicalDivisionId(ev);         
    }  
  
    const handleCheckboxChange = (checked, wrId) => {
        if (checked) {
            setSchemeIds([...SchemeIds, wrId]);
        }
        else {
            setSchemeIds(SchemeIds.filter((item) => (item !== wrId)));
        }
    }

    const clearHandler = () => {
        // localStorage.setItem('ID', null);
        setState({ ...state, remarks: '' });        
        const mech = ({ value: null, label: "Select Mechanical Division" });
        setMechanicalDivisionId(mech);    
        setSchemeIds([]);
    }

    const handleDelete = (alloId, wrId) => {
        fetch(
            service.DELETE_SCHEME_ALLOCATION_TO_MECHDIV,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    AllocationId: alloId,
                    SchemeId: wrId
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.messageType === 'Success') {
                    // eslint-disable-next-line
                    alert(result.message);
                    getSchemes();
                    getAllocationDetails();                                        
                }
                else if (result.messageType === 'Failed') {
                    // eslint-disable-next-line
                    alert(result.message);                    
                }
                else {
                    // eslint-disable-next-line
                    alert(result.message);  
                }
            });
    }

    const submitHandler = e => {
         // eslint-disable-next-line  
         console.log(SchemeIds);    // do not remove  
        e.preventDefault();        
        let flag = true;
        if (validate()) {
            setLoading(true);
            SchemeIds.forEach(element => {
                fetch(
                    service.ADD_UPDATE_SCHEME_ALLOCATION_TO_MECHDIV,
                    {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({                            
                            MechanicalDivisionId: MechanicalDivisionId.value,
                            SchemeId: element,
                            AllocationDate: moment(allocationDate).format("MM/DD/YYYY"),
                            Remarks: state.remarks,
                            AddedBy: JSON.parse(sessionStorage.getItem('userData')).userId
                        })
                    })
                    .then(resp => resp.json())
                    .then(respo => {
                        if (respo.messageType === 'Failed') {
                            flag = false;
                            // eslint-disable-next-line
                            alert(respo.message);                            
                        }
                        else {
                            flag = false;
                            // eslint-disable-next-line
                            alert(respo.message);    
                        }
                    });

            });

            if (flag) {
                // eslint-disable-next-line
                alert("Data Saved Successfully");                
                getSchemes();
                getAllocationDetails();
                setSchemeIds([]);
            }
            setLoading(false);
        }        
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Scheme Allocation To Mechanical Division
                                </MDTypography>
                            </MDBox>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                <Table>
                                    <TableBody>                                        
                                        <TableRow>
                                            <TableCell colSpan="2" align="center">
                                                <Table aria-label="simple table">
                                                    <TableRow style={{ backgroundColor: 'lightgrey' }}>
                                                        <TableCell style={{ fontWeight: 'bold' }}>Select</TableCell>
                                                        <TableCell style={{ fontWeight: 'bold' }}>Scheme</TableCell>
                                                        <TableCell style={{ fontWeight: 'bold' }}>Total Discharge</TableCell>
                                                        <TableCell style={{ fontWeight: 'bold' }}>Rising Mains</TableCell>
                                                        <TableCell style={{ fontWeight: 'bold' }}>Length</TableCell>
                                                        <TableCell style={{ fontWeight: 'bold' }}>Diameter</TableCell>                                                        
                                                    </TableRow>
                                                    <TableBody>
                                                        {SchemeData.map((row) => (
                                                            <TableRow
                                                                key={row.schemeId}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell>
                                                                    <Checkbox onChange={(e) => handleCheckboxChange(e.target.checked, row.schemeId)} id="chkSelect" />
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">{row.scheme}</TableCell>
                                                                <TableCell component="th" scope="row">{row.totalDischarge}</TableCell>
                                                                <TableCell component="th" scope="row">{row.risingMains}</TableCell>
                                                                <TableCell component="th" scope="row">{row.length}</TableCell>
                                                                <TableCell component="th" scope="row">{row.diameter}</TableCell>                                                                
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".SchemeIds"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell width="50%">
                                                <Select
                                                    name="CE"
                                                    options={CEData} placeholder="Select CE"
                                                    value={CEId}
                                                    onChange={CEChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                    isDisabled
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".ceId"]}</div>
                                            </TableCell>
                                            <TableCell width="50%">
                                                <Select
                                                    name="SE"
                                                    options={SEData} placeholder="Select SE"
                                                    value={SEId}
                                                    onChange={SEChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                    isDisabled
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".seId"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell>
                                                <Select
                                                    name="Mechanical Division"
                                                    options={MechanicalDivisionData} placeholder="Select Mechanical Division"
                                                    value={MechanicalDivisionId}
                                                    onChange={MechanicalDivisionChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".MechanicalDivisionId"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align='left' style={{ fontSize: '12px' }}>
                                                Allocation Date
                                                <DatePicker
                                                    id="allocationDate"
                                                    selected={allocationDate}
                                                    onChange={date => setAllocationDate(date)}
                                                    dateFormat="MM/dd/yyyy"
                                                    tabIndex={0}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".allocationDate"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <MDInput label="Remarks" fullWidth value={state.remarks || ''}
                                                    name="remarks"
                                                    onChange={changeHandler}
                                                    id="remarks"
                                                    required
                                                    multiline />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".remarks"]}</div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                <MDBox mt={4} mb={1} textAlign="center">
                                    {loading ? <Oval
                                        heigth="20"
                                        width="20"
                                        color='grey'
                                        ariaLabel='loading'
                                    /> :
                                        <div>
                                            <MDButton color="info" onClick={submitHandler}>
                                                SAVE
                                            </MDButton>&nbsp;&nbsp;&nbsp;&nbsp;
                                            <MDButton color="info" onClick={clearHandler}>
                                                CLEAR
                                            </MDButton>
                                        </div>
                                    }
                                    {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                </MDBox>
                            </Card>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                {loading ? <Oval
                                    heigth="100"
                                    width="100"
                                    color='grey'
                                    ariaLabel='loading'
                                /> :
                                    <TableContainer component={Paper} style={{ width: '95%', alignSelf: 'center', }}>
                                        <Table aria-label="simple table">
                                            <TableRow style={{ backgroundColor: 'lightgrey' }}>
                                                <TableCell style={{ fontWeight: 'bold' }}>Scheme</TableCell>                                                
                                                <TableCell style={{ fontWeight: 'bold' }}>Mechanical Division</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Allocation Date</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Remarks</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Action</TableCell>
                                            </TableRow>
                                            <TableBody>
                                                {allocationData.map((row) => (
                                                    <TableRow
                                                        key={row.allocationId}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">{row.scheme}</TableCell>  
                                                        <TableCell component="th" scope="row">{row.mechanicalDivision}</TableCell>                                                        
                                                        <TableCell component="th" scope="row">{moment(row.allocationDate).format('DD/MM/YYYY')}</TableCell>
                                                        <TableCell component="th" scope="row">{row.remarks}</TableCell>
                                                        <TableCell><MDButton onClick={() => handleDelete(row.allocationId, row.schemeId)}>Delete</MDButton></TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                }
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
                <Footer />
            </div>
        </DashboardLayout>
    );
}
export default SchemeAllocationToMechanicalDivision;