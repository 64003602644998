
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
// import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
import { Circles } from 'react-loader-spinner';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import * as moment from 'moment';
import { Radio } from '@mui/material';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
// import { jsPDF } from 'jspdf';
// import html2canvas from "html2canvas";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { styled } from '@mui/material/styles';
import * as service from '../../services/service';

function Reports() {
    const [dailyUtilizationReportData, setDailyUtilizationReportData] = useState([]);
    const [monthlyUtilizationReportData, setMonthlyUtilizationReportData] = useState([]);
    const [waterLiftingReportData, setWaterLiftingReportData] = useState([]);
    const [logBookReportData, setLogBookReportData] = useState([]);
    const [expenditureReportData, setExpenditureReportData] = useState([]);
    const [showDailyUtilizationReport, setShowDailyUtilizationReport] = useState(false);
    const [showMonthlyUtilizationReport, setShowMonthlyUtilizationReport] = useState(false);
    const [showWaterLiftingReport, setShowWaterLiftingReport] = useState(false);
    const [showLogBookReport, setShowLogBookReport] = useState(false);
    const [showExpenditureReport, setShowExpenditureReport] = useState(false);
    const [reportType, setReportType] = useState('');
    const [CEData, setCEData] = useState([]);
    const [SEData, setSEData] = useState([]);
    const [MechanicalDivisionData, setMechanicalDivisionData] = useState([]);
    const [MechanicalSubDivisionData, setMechanicalSubDivisionData] = useState([]);
    const [CEId, setCEId] = useState('');
    const [SEId, setSEId] = useState('');
    const [MechanicalDivisionId, setMechanicalDivisionId] = useState('');
    const [MechanicalSubDivisionId, setMechanicalSubDivisionId] = useState('');
    const [SchemeData, setSchemeData] = useState([]);
    const [SchemeId, setSchemeId] = useState('');
    const [PumpData, setPumpData] = useState([]);
    const [PumpId, setPumpId] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [month, setMonth] = useState(null);
    const [year, setYear] = useState(null);
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const monthData = [{ label: "January", value: 1 }, { label: "February", value: 2 }, { label: "March", value: 3 }, { label: "April", value: 4 },
    { label: "May", value: 5 }, { label: "June", value: 6 }, { label: "July", value: 7 }, { label: "August", value: 8 },
    { label: "September", value: 9 }, { label: "October", value: 10 }, { label: "November", value: 11 }, { label: "December", value: 12 }];
    const yearData = [{ label: "2020", value: 2020 }, { label: "2021", value: 2021 }, { label: "2022", value: 2022 }, { label: "2023", value: 2023 },
    { label: "2024", value: 2024 }, { label: "2025", value: 2025 }, { label: "2026", value: 2026 }, { label: "2027", value: 2027 },
    { label: "2028", value: 2028 }, { label: "2029", value: 2029 }, { label: "2030", value: 2030 }];
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    const userLvl = JSON.parse(sessionStorage.getItem('userData')).userLevel;

    const cellStyle = {
        fontWeight: 'bold',
        fontSize: '14px',
        borderWidth: "1px",
        borderColor: "#aaaaaa",
        borderStyle: 'solid',
    }

    const getCEs = () => {
        fetch(
            service.CE_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.ceId, label: item.ce }));
                setCEData(data2);
            });
    };

    const getSchemes = (id) => {
        const errs = {};
        fetch(
            service.GET_SCHEME_ALLOCATION_TO_MECHSUBDIV_BY_SUBDIVID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    MechanicalSubDivisionId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.schemeId, label: item.scheme }));
                const all = ({ value: "0", label: "All" });
                data2.unshift(all);
                setSchemeData(data2);
            })
            .catch(err => {
                if (err) {
                    errs[".invalid"] = err;
                    setErrors(err);
                };
            });
    };

    const getMechanicalSubDivisions = (id) => {
        fetch(
            service.GET_MECHANICAL_SUB_DIVISION_BY_MECH_DIV_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    MechanicalDivisionId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.mechanicalSubDivisionId, label: item.mechanicalSubDivision }));
                const all = ({ value: "0", label: "All" });
                data2.unshift(all);
                setMechanicalSubDivisionId(all);
                setMechanicalSubDivisionData(data2);
                if (JSON.parse(sessionStorage.getItem('userData')).mechanicalSubDivisionId > 0) {
                    const sb = ({ label: JSON.parse(sessionStorage.getItem('userData')).mechanicalSubDivision, value: JSON.parse(sessionStorage.getItem('userData')).mechanicalSubDivisionId });
                    setMechanicalSubDivisionId(sb);
                    getSchemes(sb.value);
                }
            });
    };

    const getMechanicalDivisions = (id) => {
        fetch(
            service.GET_MECHANICAL_DIVISION_BY_SE_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    SEId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.mechanicalDivisionId, label: item.mechanicalDivision }));
                const all = ({ value: "0", label: "All" });
                data2.unshift(all);
                setMechanicalDivisionData(data2);
                setMechanicalDivisionId(all);
                if (JSON.parse(sessionStorage.getItem('userData')).mechanicalDivisionId > 0) {
                    const dv = ({ label: JSON.parse(sessionStorage.getItem('userData')).mechanicalDivision, value: JSON.parse(sessionStorage.getItem('userData')).mechanicalDivisionId });
                    setMechanicalDivisionId(dv);
                    getMechanicalSubDivisions(dv.value);
                }
            });
    };

    const getSEs = (id) => {
        fetch(
            service.GET_SE_BY_CE_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CEId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.seId, label: item.se }));
                const all = ({ value: "0", label: "All" });
                data2.unshift(all);
                setSEData(data2);
                setSEId(all);
                if (JSON.parse(sessionStorage.getItem('userData')).seId > 0) {
                    const sc = ({ label: JSON.parse(sessionStorage.getItem('userData')).se, value: JSON.parse(sessionStorage.getItem('userData')).seId });
                    setSEId(sc);
                    getMechanicalDivisions(JSON.parse(sessionStorage.getItem('userData')).seId);
                }
            });
    };

    const getPumps = (id) => {
        const errs = {};
        fetch(
            service.PUMP_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    SchemeId: id
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.pumpId, label: item.pump }));
                const all = ({ value: "0", label: "All" });
                data2.unshift(all);
                setPumpData(data2);
            })
            .catch(err => {
                if (err) {
                    errs[".invalid"] = err;
                    setErrors(err);
                };
            });
    };

    useEffect(() => {
        getCEs();
        const cc = ({ label: JSON.parse(sessionStorage.getItem('userData')).ce, value: JSON.parse(sessionStorage.getItem('userData')).ceId });
        setCEId(cc);
        getSEs(cc.value);
    }, []);

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!reportType) {
            isValid = false;
            errs[".reportType"] = "Please select report type";
        }
        if (reportType !== "waterLiftingReportData" && (!MechanicalSubDivisionId || MechanicalSubDivisionId.value === "0")) {
            isValid = false;
            errs[".MechanicalSubDivisionId"] = "Please select mechanical sub division";
        }
        if (reportType !== "waterLiftingReportData" && !SchemeId) {
            isValid = false;
            errs[".SchemeId"] = "Please select Scheme";
        }
        if (reportType === "dailyUtilizationReportData" && !month) {
            isValid = false;
            errs[".month"] = "Please select Month";
        }
        if (reportType === "dailyUtilizationReportData" && !year) {
            isValid = false;
            errs[".year"] = "Please select Year";
        }
        if (reportType === "monthlyUtilizationReportData" && !year) {
            isValid = false;
            errs[".year"] = "Please select Year";
        }
        if (reportType === "waterLiftingReportData" && !fromDate) {
            isValid = false;
            errs[".fromDate"] = "Please select from date";
        }
        if (reportType === "waterLiftingReportData" && !toDate) {
            isValid = false;
            errs[".toDate"] = "Please select to date";
        }
        if (reportType === "logBookReportData" && !fromDate) {
            isValid = false;
            errs[".fromDate"] = "Please select from date";
        }
        if (reportType === "logBookReportData" && !PumpId) {
            isValid = false;
            errs[".PumpId"] = "Please select Pump";
        }
        setErrors(errs);
        return isValid;
    }

    const handleChange = (event) => {
        setShowDailyUtilizationReport(false);
        setShowMonthlyUtilizationReport(false);
        setShowWaterLiftingReport(false);
        setShowLogBookReport(false);
        setShowExpenditureReport(false);
        setReportType(event.target.value);
    };

    const CEChangeHandler = (ev) => {
        setCEId(ev);
        getSEs(ev.value);
    }
    const SEChangeHandler = (ev) => {
        setSEId(ev);
        setMechanicalDivisionData([]);
        getMechanicalDivisions(ev.value);
    }
    const MechanicalDivisionChangeHandler = (ev) => {
        setMechanicalDivisionId(ev);
        setMechanicalSubDivisionData([]);
        getMechanicalSubDivisions(ev.value);
    }
    const MechanicalSubDivisionChangeHandler = (ev) => {
        setMechanicalSubDivisionId(ev);
        getSchemes(ev.value);
    }
    const SchemeChangeHandler = (ev) => {
        setSchemeId(ev);
        getPumps(ev.value);
    }
    const PumpChangeHandler = (ev) => {
        setPumpId(ev);
    }
    const MonthChangeHandler = (ev) => {
        setMonth(ev);
    }
    const YearChangeHandler = (ev) => {
        setYear(ev);
    }

    const getHeaders = () => {
        let headers = '';
        if (waterLiftingReportData.length > 0) {
            const column = waterLiftingReportData[0];
            const title = Object.keys(column);
            const head = title.map((data) =>
                <TableCell key={data} style={cellStyle}>{data}</TableCell>
            )
            headers = head;
        }
        return headers;
    }

    const getRows = () => {
        let row = '';
        if (waterLiftingReportData.length > 0) {
            const column = waterLiftingReportData[0];
            const title = Object.keys(column);
            const rw = waterLiftingReportData.map((data) =>
                <TableRow>
                    {
                        title.map((i) =>
                            <TableCell style={cellStyle}>{data[i]}</TableCell>
                        )
                    }
                </TableRow>
            )
            row = rw;
        }
        return row;
    }


    const getHeadersForExpenditure = () => {
        let headers = '';
        if (expenditureReportData.length > 0) {
            const column = expenditureReportData[0];
            const title = Object.keys(column);
            const head = title.map((data) =>
                <TableCell key={data} style={cellStyle}>{data}</TableCell>
            )
            headers = head;
        }
        return headers;
    }

    const getRowsForExpenditure = () => {
        let row = '';
        if (expenditureReportData.length > 0) {
            const column = expenditureReportData[0];
            const title = Object.keys(column);
            const rw = expenditureReportData.map((data) =>
                <TableRow>
                    {
                        title.map((i) =>
                            <TableCell style={cellStyle}>{data[i]}</TableCell>
                        )
                    }
                </TableRow>
            )
            row = rw;
        }
        return row;
    }

    // const getRateRowsForExpenditure = () => {
    //     let row = '';
    //     if (expenditureReportData.length > 0) {
    //         const column = expenditureReportData[0];
    //         const title = Object.keys(column);
    //         let cell = '';
    //         const rw = expenditureReportData.map((element, index) => {
    //             if (index === expenditureReportData.length - 1) {
    //                 const data = expenditureReportData[index - 1];
    //                 <TableRow>
    //                     {
    //                         cell = title.map((col, ind) => {

    //                             let rate = 0;
    //                             if (ind >= 4) {
    //                                 if (element[col] > 0) {
    //                                     rate = data[col] / element[col];
    //                                 }
    //                                 <TableCell style={cellStyle}>{rate}</TableCell>
    //                             }

    //                             return cell;
    //                         })
    //                     }
    //                 </TableRow>
    //             }                           
    //         })
    //         row = rw;                      
    //     }       
    //     return row; 
    // }

    function GetDailyUtilizationReportData() {
        const errs = {};
        fetch(
            service.GET_DAILY_PUMP_UTILIZATION_REPORT,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    MechanicalSubDivisionId: MechanicalSubDivisionId.value,
                    SchemeId: SchemeId.value,
                    PumpId: PumpId ? PumpId.value : 0,
                    Month: month.value,
                    Year: year.value
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.length === 0) {
                    errs[".invalid"] = 'No Data Found!';
                    setErrors(errs);
                    setShowDailyUtilizationReport(false);
                    setShowMonthlyUtilizationReport(false);
                    setShowWaterLiftingReport(false);
                    setShowLogBookReport(false);
                    setShowExpenditureReport(false);
                }
                else if (result.length > 0 && result[0].message.messageType === 'Success') {
                    setShowDailyUtilizationReport(true);
                    setShowMonthlyUtilizationReport(false);
                    setShowWaterLiftingReport(false);
                    setShowLogBookReport(false);
                    setShowExpenditureReport(false);
                    setDailyUtilizationReportData(result);
                    errs[".invalid"] = '';
                    setErrors(errs);
                }
                else if (result[0].message.messageType === 'Failed') {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowDailyUtilizationReport(false);
                    setShowMonthlyUtilizationReport(false);
                    setShowWaterLiftingReport(false);
                    setShowLogBookReport(false);
                    setShowExpenditureReport(false);
                }
                else {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowDailyUtilizationReport(false);
                    setShowMonthlyUtilizationReport(false);
                    setShowWaterLiftingReport(false);
                    setShowLogBookReport(false);
                    setShowExpenditureReport(false);
                }
            });
    }

    function GetMonthlyUtilizationReportData() {
        const errs = {};
        fetch(
            service.GET_MONTHLY_PUMP_UTILIZATION_REPORT,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    MechanicalSubDivisionId: MechanicalSubDivisionId.value,
                    SchemeId: SchemeId.value,
                    PumpId: PumpId ? PumpId.value : 0,
                    Year: year.value
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.length === 0) {
                    errs[".invalid"] = 'No Data Found!';
                    setErrors(errs);
                    setShowDailyUtilizationReport(false);
                    setShowMonthlyUtilizationReport(false);
                    setShowWaterLiftingReport(false);
                    setShowLogBookReport(false);
                    setShowExpenditureReport(false);
                }
                else if (result.length > 0 && result[0].message.messageType === 'Success') {
                    setShowDailyUtilizationReport(false);
                    setShowMonthlyUtilizationReport(true);
                    setShowWaterLiftingReport(false);
                    setShowLogBookReport(false);
                    setShowExpenditureReport(false);
                    setMonthlyUtilizationReportData(result);
                    errs[".invalid"] = '';
                    setErrors(errs);
                }
                else if (result[0].message.messageType === 'Failed') {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowDailyUtilizationReport(false);
                    setShowMonthlyUtilizationReport(false);
                    setShowWaterLiftingReport(false);
                    setShowLogBookReport(false);
                    setShowExpenditureReport(false);
                }
                else {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowDailyUtilizationReport(false);
                    setShowMonthlyUtilizationReport(false);
                    setShowWaterLiftingReport(false);
                    setShowLogBookReport(false);
                    setShowExpenditureReport(false);
                }
            });
    }

    function GetWaterLiftingReportData() {
        const errs = {};
        fetch(
            service.GET_WATER_LIFTING_REPORT,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CEId: JSON.parse(sessionStorage.getItem('userData')).ceId,
                    SEId: SEId ? SEId.value : 0,
                    MechanicalDivisionId: MechanicalDivisionId ? MechanicalDivisionId.value : 0,
                    MechanicalSubDivisionId: MechanicalSubDivisionId ? MechanicalSubDivisionId.value : 0,
                    SchemeId: SchemeId ? SchemeId.value : 0,
                    FromDate: moment(fromDate).format("MM/DD/YYYY"),
                    ToDate: moment(toDate).format("MM/DD/YYYY")
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.length === 0) {
                    errs[".invalid"] = 'No Data Found!';
                    setErrors(errs);
                    setShowDailyUtilizationReport(false);
                    setShowMonthlyUtilizationReport(false);
                    setShowWaterLiftingReport(false);
                    setShowLogBookReport(false);
                    setShowExpenditureReport(false);
                }
                else if (result.length > 0) {
                    setShowDailyUtilizationReport(false);
                    setShowMonthlyUtilizationReport(false);
                    setShowWaterLiftingReport(true);
                    setShowLogBookReport(false);
                    setShowExpenditureReport(false);
                    setWaterLiftingReportData(result);
                    errs[".invalid"] = '';
                    setErrors(errs);
                }
                else {
                    errs[".invalid"] = 'Something Went Wrong!';
                    setErrors(errs);
                    setShowDailyUtilizationReport(false);
                    setShowMonthlyUtilizationReport(false);
                    setShowWaterLiftingReport(false);
                    setShowLogBookReport(false);
                    setShowExpenditureReport(false);
                }
            });
    }

    function GetLogBookReportData() {
        const errs = {};
        fetch(
            service.PUMP_LOG_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    SchemeId: SchemeId.value,
                    PumpId: PumpId ? PumpId.value : 0,
                    WorkDate: moment(fromDate).format("MM/DD/YYYY"),
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.length === 0) {
                    errs[".invalid"] = 'No Data Found!';
                    setErrors(errs);
                    setShowDailyUtilizationReport(false);
                    setShowMonthlyUtilizationReport(false);
                    setShowWaterLiftingReport(false);
                    setShowLogBookReport(false);
                    setShowExpenditureReport(false);
                }
                else if (result.length > 0 && result[0].message.messageType === 'Success') {
                    setShowDailyUtilizationReport(false);
                    setShowMonthlyUtilizationReport(false);
                    setShowWaterLiftingReport(false);
                    setShowLogBookReport(true);
                    setShowExpenditureReport(false);
                    setLogBookReportData(result);
                    errs[".invalid"] = '';
                    setErrors(errs);
                }
                else if (result[0].message.messageType === 'Failed') {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowDailyUtilizationReport(false);
                    setShowMonthlyUtilizationReport(false);
                    setShowWaterLiftingReport(false);
                    setShowLogBookReport(false);
                    setShowExpenditureReport(false);
                }
                else {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowDailyUtilizationReport(false);
                    setShowMonthlyUtilizationReport(false);
                    setShowWaterLiftingReport(false);
                    setShowLogBookReport(false);
                    setShowExpenditureReport(false);
                }
            });
    }

    function GetExpenditureReportData() {
        const errs = {};
        fetch(
            service.GET_EXPENDITURE_REPORT,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CEId: JSON.parse(sessionStorage.getItem('userData')).ceId,
                    SEId: SEId ? SEId.value : 0,
                    MechanicalDivisionId: MechanicalDivisionId ? MechanicalDivisionId.value : 0,
                    MechanicalSubDivisionId: MechanicalSubDivisionId ? MechanicalSubDivisionId.value : 0,
                    SchemeId: SchemeId ? SchemeId.value : 0,
                    PumpId: PumpId ? PumpId.value : 0,
                    FromDate: moment(fromDate).format("MM/DD/YYYY"),
                    ToDate: moment(toDate).format("MM/DD/YYYY")
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.length === 0) {
                    errs[".invalid"] = 'No Data Found!';
                    setErrors(errs);
                    setShowDailyUtilizationReport(false);
                    setShowMonthlyUtilizationReport(false);
                    setShowWaterLiftingReport(false);
                    setShowLogBookReport(false);
                    setShowExpenditureReport(false);
                }
                else if (result.length > 0) {
                    setShowDailyUtilizationReport(false);
                    setShowMonthlyUtilizationReport(false);
                    setShowWaterLiftingReport(false);
                    setShowLogBookReport(false);
                    setShowExpenditureReport(true);
                    setExpenditureReportData(result);
                    errs[".invalid"] = '';
                    setErrors(errs);
                }
                else {
                    errs[".invalid"] = 'Something Went Wrong!';
                    setErrors(errs);
                    setShowDailyUtilizationReport(false);
                    setShowMonthlyUtilizationReport(false);
                    setShowWaterLiftingReport(false);
                    setShowLogBookReport(false);
                    setShowExpenditureReport(false);
                }
            });
    }

    const submitHandler = e => {
        setShowDailyUtilizationReport(false);
        setShowMonthlyUtilizationReport(false);
        setShowWaterLiftingReport(false);
        setShowLogBookReport(false);
        setShowExpenditureReport(false);
        e.preventDefault();
        if (validate()) {
            setLoading(true);
            if (reportType === "dailyUtilizationReportData") {
                GetDailyUtilizationReportData();
            }
            else if (reportType === "monthlyUtilizationReportData") {
                GetMonthlyUtilizationReportData();
            }
            else if (reportType === "waterLiftingReportData") {
                GetWaterLiftingReportData();
            }
            else if (reportType === "logBookReportData") {
                GetLogBookReportData();
            }
            else if (reportType === "expenditureReportData") {
                GetExpenditureReportData();
            }
            setLoading(false);
        }
    }


    const BpIcon = styled('span')(({ theme }) => ({
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow:
            theme.palette.mode === 'dark'
                ? '0 0 0 1px rgb(16 22 26 / 40%)'
                : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
        backgroundImage:
            theme.palette.mode === 'dark'
                ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
                : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background:
                theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
        },
    }));
    const BpCheckedIcon = styled(BpIcon)({
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    });


    const ShowDailyUtilizationReport = React.memo(() => (
        <TableContainer component={Paper} style={{ width: '100%', alignSelf: 'center' }}>
            <Table id="dvDailyUtilizationReport">
                <TableBody>
                    <TableRow>
                        <TableCell colSpan="33">
                            <Table>
                                <TableRow>
                                    <TableCell colSpan="5" align="center" style={{ fontWeight: 'bold', fontSize: '20px' }}>
                                        Daily Utilization Report
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell width="25%" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                        Mechanical Sub Division : {MechanicalSubDivisionId.label}
                                    </TableCell>
                                    <TableCell width="25%" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                        Scheme : {SchemeId.label}
                                    </TableCell>
                                    <TableCell width="20%" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                        Pump : {PumpId ? PumpId.label : 'All'}
                                    </TableCell>
                                    <TableCell width="10%" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                        Month : {month.label}
                                    </TableCell>
                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                        Year : {year.value}
                                    </TableCell>
                                </TableRow>
                            </Table>
                        </TableCell>
                    </TableRow>
                    <TableRow style={{ backgroundColor: 'lightgrey' }}>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Scheme</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>Pump</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>1</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>2</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>3</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>4</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>5</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>6</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>7</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>8</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>9</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>10</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>11</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>12</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>13</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>14</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>15</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>16</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>17</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>18</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>19</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>20</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>21</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>22</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>23</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>24</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>25</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>26</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>27</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>28</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>29</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>30</TableCell>
                        {month.value === 1 || month.value === 3 || month.value === 5 || month.value === 7
                            || month.value === 8 || month.value === 10 || month.value === 12 ?
                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>31</TableCell> : null
                        }
                    </TableRow>
                    {dailyUtilizationReportData.map((row) => (
                        <TableRow
                            key={row.pumpId}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell style={{ fontSize: '14px' }}>{row.scheme}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.pump}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.dateOne}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.dateTwo}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.dateThree}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.dateFour}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.dateFive}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.dateSix}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.dateSeven}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.dateEight}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.dateNine}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.dateTen}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.dateEleven}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.dateTwelve}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.dateThirteen}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.dateFourteen}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.dateFifteen}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.dateSixteen}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.dateSeventeen}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.dateEighteen}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.dateNinteen}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.dateTwenty}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.dateTwentyOne}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.dateTwentyTwo}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.dateTwentyThree}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.dateTwentyFour}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.dateTwentyFive}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.dateTwentySix}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.dateTwentySeven}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.dateTwentyEight}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.dateTwentyNine}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.dateThirty}</TableCell>
                            {month.value === 1 || month.value === 3 || month.value === 5 || month.value === 7
                                || month.value === 8 || month.value === 10 || month.value === 12 ?
                                <TableCell style={{ fontSize: '14px' }}>{row.dateThirtyOne}</TableCell>
                                : null
                            }
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    ));

    const ShowMonthlyUtilizationReport = React.memo(() => (
        <TableContainer component={Paper} style={{ width: '100%', alignSelf: 'center' }}>
            <Table id="dvMonthlyUtilizationReport">
                <TableBody>
                    <TableRow>
                        <TableCell colSpan="15">
                            <Table>
                                <TableRow>
                                    <TableCell colSpan="4" align="center" style={{ fontWeight: 'bold', fontSize: '20px' }}>
                                        Monthly Utilization Report
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell width="25%" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                        Mechanical Sub Division : {MechanicalSubDivisionId.label}
                                    </TableCell>
                                    <TableCell width="25%" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                        Scheme : {SchemeId.label}
                                    </TableCell>
                                    <TableCell width="20%" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                        Pump : {PumpId ? PumpId.label : 'All'}
                                    </TableCell>
                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                        Year : {year.value}
                                    </TableCell>
                                </TableRow>
                            </Table>
                        </TableCell>
                    </TableRow>
                    <TableRow style={{ backgroundColor: 'lightgrey' }}>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Scheme</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Pump</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>January {year.value}</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>February {year.value}</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>March {year.value}</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>April {year.value}</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>May {year.value}</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>June {year.value}</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>July {year.value}</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>August {year.value}</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>September {year.value}</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>October {year.value}</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>November {year.value}</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>December {year.value}</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Cumulative Hrs</TableCell>
                    </TableRow>
                    {monthlyUtilizationReportData.map((row) => (
                        <TableRow
                            key={row.pumpId}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell style={{ fontSize: '14px' }}>{row.scheme}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.pump}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.january}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.february}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.march}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.april}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.may}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.june}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.july}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.august}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.september}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.october}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.november}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.december}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.january + row.february + row.march
                                + row.april + row.may + row.june + row.july
                                + row.august + row.september + row.october
                                + row.november + row.december}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    ));

    const ShowWaterLiftingReport = React.memo(() => (
        <TableContainer component={Paper} style={{ width: '100%', alignSelf: 'center' }}>
            <Table id="dvWaterLiftingReport">
                <TableBody>
                    <TableRow>
                        <TableCell colSpan="15">
                            <Table>
                                <TableRow>
                                    <TableCell colSpan="4" align="center" style={{ fontWeight: 'bold', fontSize: '20px' }}>
                                        पाणी उचल प्रपत्र
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell width="25%" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                        CE : {JSON.parse(sessionStorage.getItem('userData')).ce}
                                    </TableCell>
                                    <TableCell width="25%" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                        SE : {SEId ? SEId.label : 'All'}
                                    </TableCell>
                                    <TableCell width="20%" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                        Mechanical Division : {MechanicalDivisionId ? MechanicalDivisionId.label : 'All'}
                                    </TableCell>
                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }} />
                                </TableRow>
                                <TableRow>
                                    <TableCell width="25%" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                        Mechanical Sub Division : {MechanicalSubDivisionId ? MechanicalSubDivisionId.label : 'All'}
                                    </TableCell>
                                    <TableCell width="25%" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                        Scheme : {SchemeId ? SchemeId.label : 'All'}
                                    </TableCell>
                                    <TableCell width="20%" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                        From Date : {moment(fromDate).format("DD/MM/YYYY")}
                                    </TableCell>
                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                        To Date : {moment(toDate).format("DD/MM/YYYY")}
                                    </TableCell>
                                </TableRow>
                            </Table>
                        </TableCell>
                    </TableRow>
                    <TableRow style={{ backgroundColor: 'lightgrey' }}>
                        {getHeaders()}
                    </TableRow>
                    {getRows()}
                </TableBody>
            </Table>
        </TableContainer>
    ));

    const ShowLogBookReport = React.memo(() => (
        <TableContainer component={Paper} style={{ width: '100%', alignSelf: 'center' }}>
            <Table id="dvLogBookReport">
                <TableBody>
                    <TableRow>
                        <TableCell colSpan="10">
                            <Table>
                                <TableRow>
                                    <TableCell colSpan="4" align="center" style={{ fontWeight: 'bold', fontSize: '20px' }}>
                                        Log Book Report
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell width="25%" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                        Mechanical Sub Division : {MechanicalSubDivisionId.label}
                                    </TableCell>
                                    <TableCell width="25%" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                        Scheme : {SchemeId.label}
                                    </TableCell>
                                    <TableCell width="20%" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                        Pump : {PumpId ? PumpId.label : 'All'}
                                    </TableCell>
                                    <TableCell width="10%" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                        Date : {moment(fromDate).format("DD/MM/YYYY")}
                                    </TableCell>
                                </TableRow>
                            </Table>
                        </TableCell>
                    </TableRow>
                    <TableRow style={{ backgroundColor: 'lightgrey' }}>
                        <TableCell style={{ fontWeight: 'bold' }}>Log Time</TableCell>
                        <TableCell colSpan="3" align="center" style={{ fontWeight: 'bold' }}>Incoming Voltage (HT Side)</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>Incoming Power Factor COS</TableCell>
                        <TableCell colSpan="3" align="center" style={{ fontWeight: 'bold' }}>Current</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>Pump Power Power Factor COS</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>Pump Gauge Pressure</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ fontWeight: 'bold' }} />
                        <TableCell align="center" style={{ fontWeight: 'bold' }}>Rv</TableCell>
                        <TableCell align="center" style={{ fontWeight: 'bold' }}>Yv</TableCell>
                        <TableCell align="center" style={{ fontWeight: 'bold' }}>Bv</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }} />
                        <TableCell align="center" style={{ fontWeight: 'bold' }}>Ra</TableCell>
                        <TableCell align="center" style={{ fontWeight: 'bold' }}>Ya</TableCell>
                        <TableCell align="center" style={{ fontWeight: 'bold' }}>Ba</TableCell>
                        <TableCell style={{ fontWeight: 'bold' }} />
                        <TableCell style={{ fontWeight: 'bold' }} />
                    </TableRow>
                    {logBookReportData.map((row) => (
                        <TableRow
                            key={row.logId}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell style={{ fontSize: '14px' }}>{row.logTime}</TableCell>
                            <TableCell align="center" style={{ fontSize: '14px' }}>{row.incomingRed ? row.incomingRed : 0}</TableCell>
                            <TableCell align="center" style={{ fontSize: '14px' }}>{row.incomingYellow ? row.incomingYellow : 0}</TableCell>
                            <TableCell align="center" style={{ fontSize: '14px' }}>{row.incomingBlue ? row.incomingBlue : 0}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.incomingPower}</TableCell>
                            <TableCell align="center" style={{ fontSize: '14px' }}>{row.PumpRed ? row.PumpRed : 0}</TableCell>
                            <TableCell align="center" style={{ fontSize: '14px' }}>{row.PumpYellow ? row.PumpYellow : 0}</TableCell>
                            <TableCell align="center" style={{ fontSize: '14px' }}>{row.PumpBlue ? row.PumpBlue : 0}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.pumpPower}</TableCell>
                            <TableCell style={{ fontSize: '14px' }}>{row.pumpGaugePressure}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer >

    ));

    const ShowExpenditureReport = React.memo(() => (
        <TableContainer component={Paper} style={{ width: '100%', alignSelf: 'center' }}>
            <Table id="dvExpenditureReport">
                <TableBody>
                    <TableRow>
                        <TableCell colSpan="15">
                            <Table>
                                <TableRow>
                                    <TableCell colSpan="4" align="center" style={{ fontWeight: 'bold', fontSize: '20px' }}>
                                        Expenditure for OM & R for {SchemeId ? SchemeId.label : ''}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell width="25%" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                        CE : {JSON.parse(sessionStorage.getItem('userData')).ce}
                                    </TableCell>
                                    <TableCell width="25%" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                        SE : {SEId ? SEId.label : 'All'}
                                    </TableCell>
                                    <TableCell width="20%" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                        Mechanical Division : {MechanicalDivisionId ? MechanicalDivisionId.label : 'All'}
                                    </TableCell>
                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }} />
                                </TableRow>
                                <TableRow>
                                    <TableCell width="25%" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                        Mechanical Sub Division : {MechanicalSubDivisionId ? MechanicalSubDivisionId.label : 'All'}
                                    </TableCell>
                                    <TableCell width="25%" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                        Scheme : {SchemeId ? SchemeId.label : 'All'}
                                    </TableCell>
                                    <TableCell width="20%" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                        From Date : {moment(fromDate).format("DD/MM/YYYY")}
                                    </TableCell>
                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                        To Date : {moment(toDate).format("DD/MM/YYYY")}
                                    </TableCell>
                                </TableRow>
                            </Table>
                        </TableCell>
                    </TableRow>
                    <TableRow style={{ backgroundColor: 'lightgrey' }}>
                        {getHeadersForExpenditure()}
                    </TableRow>
                    {getRowsForExpenditure()}
                    <TableRow>
                        <TableCell align="right" colSpan="15" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                            <br />
                            <br />
                            <br />
                            {JSON.parse(sessionStorage.getItem('userData')).userName}
                            <br />
                            {JSON.parse(sessionStorage.getItem('userData')).designation}
                            <br />
                            {JSON.parse(sessionStorage.getItem('userData')).mechanicalSubDivision}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    ));

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Reports
                                </MDTypography>
                            </MDBox>
                            <Card style={{ alignItems: 'center' }}>
                                <br />
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell style={{ fontSize: '14px', fontWeight: 'bold', width: '25%' }}>
                                                Daily Utilization Report
                                                <Radio
                                                    checked={reportType === 'dailyUtilizationReportData'}
                                                    onChange={handleChange}
                                                    value="dailyUtilizationReportData"
                                                    name="radio-buttons"
                                                    inputProps={{ 'aria-label': 'A' }}
                                                    title="Daily Utilization Report"
                                                    sx={{
                                                        '&:hover': {
                                                            bgcolor: 'transparent',
                                                        },
                                                    }}
                                                    disableRipple
                                                    color="default"
                                                    checkedIcon={<BpCheckedIcon />}
                                                    icon={<BpIcon />}
                                                />
                                            </TableCell>
                                            <TableCell style={{ fontSize: '14px', fontWeight: 'bold', width: '25%' }}>
                                                Monthly Utilization Report
                                                <Radio
                                                    checked={reportType === 'monthlyUtilizationReportData'}
                                                    onChange={handleChange}
                                                    value="monthlyUtilizationReportData"
                                                    name="radio-buttons"
                                                    inputProps={{ 'aria-label': 'A' }}
                                                    title="Monthly Utilization Report"
                                                    sx={{
                                                        '&:hover': {
                                                            bgcolor: 'transparent',
                                                        },
                                                    }}
                                                    disableRipple
                                                    color="default"
                                                    checkedIcon={<BpCheckedIcon />}
                                                    icon={<BpIcon />}
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontSize: '14px', fontWeight: 'bold', width: '25%' }}>
                                                Water Lifting Report
                                                <Radio
                                                    checked={reportType === 'waterLiftingReportData'}
                                                    onChange={handleChange}
                                                    value="waterLiftingReportData"
                                                    name="radio-buttons"
                                                    inputProps={{ 'aria-label': 'A' }}
                                                    title="Water Lifting Report"
                                                    sx={{
                                                        '&:hover': {
                                                            bgcolor: 'transparent',
                                                        },
                                                    }}
                                                    disableRipple
                                                    color="default"
                                                    checkedIcon={<BpCheckedIcon />}
                                                    icon={<BpIcon />}
                                                />
                                            </TableCell>
                                            <TableCell style={{ fontSize: '14px', fontWeight: 'bold', width: '25%' }}>
                                                Pump Status Report
                                                <Radio
                                                    checked={reportType === 'pumpStatusReportData'}
                                                    onChange={handleChange}
                                                    value="pumpStatusReportData"
                                                    name="radio-buttons"
                                                    inputProps={{ 'aria-label': 'A' }}
                                                    title="Water Lifting Report"
                                                    sx={{
                                                        '&:hover': {
                                                            bgcolor: 'transparent',
                                                        },
                                                    }}
                                                    disableRipple
                                                    color="default"
                                                    checkedIcon={<BpCheckedIcon />}
                                                    icon={<BpIcon />}
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontSize: '14px', fontWeight: 'bold', width: '25%' }}>
                                                Log Book Report
                                                <Radio
                                                    checked={reportType === 'logBookReportData'}
                                                    onChange={handleChange}
                                                    value="logBookReportData"
                                                    name="radio-buttons"
                                                    inputProps={{ 'aria-label': 'A' }}
                                                    title="Water Lifting Report"
                                                    sx={{
                                                        '&:hover': {
                                                            bgcolor: 'transparent',
                                                        },
                                                    }}
                                                    disableRipple
                                                    color="default"
                                                    checkedIcon={<BpCheckedIcon />}
                                                    icon={<BpIcon />}
                                                />
                                            </TableCell>
                                            <TableCell style={{ fontSize: '14px', fontWeight: 'bold', width: '25%' }}>
                                                Expenditure Report
                                                <Radio
                                                    checked={reportType === 'expenditureReportData'}
                                                    onChange={handleChange}
                                                    value="expenditureReportData"
                                                    name="radio-buttons"
                                                    inputProps={{ 'aria-label': 'A' }}
                                                    title="Expenditure Report"
                                                    sx={{
                                                        '&:hover': {
                                                            bgcolor: 'transparent',
                                                        },
                                                    }}
                                                    disableRipple
                                                    color="default"
                                                    checkedIcon={<BpCheckedIcon />}
                                                    icon={<BpIcon />}
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan="2">
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".reportType"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell width="50%">
                                                <Select
                                                    name="CE"
                                                    options={CEData} placeholder="Select CE"
                                                    value={CEId}
                                                    onChange={CEChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                    isDisabled
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".ceId"]}</div>
                                            </TableCell>
                                            <TableCell width="50%">
                                                <Select
                                                    name="SE"
                                                    options={SEData} placeholder="Select SE"
                                                    value={SEId}
                                                    onChange={SEChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                    isDisabled={userLvl === 'SE' || userLvl === 'Mechanical Division' || userLvl === 'Mechanical Sub Division'}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".seId"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Select
                                                    name="Mechanical Division"
                                                    options={MechanicalDivisionData} placeholder="Select Mechanical Division"
                                                    value={MechanicalDivisionId}
                                                    onChange={MechanicalDivisionChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                    isDisabled={userLvl === 'Mechanical Division' || userLvl === 'Mechanical Sub Division'}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".MechanicalDivisionId"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <Select
                                                    name="Mechanical Sub Division"
                                                    options={MechanicalSubDivisionData} placeholder="Select Mechanical Sub Division"
                                                    value={MechanicalSubDivisionId}
                                                    onChange={MechanicalSubDivisionChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                    isDisabled={userLvl === 'Mechanical Sub Division'}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".MechanicalSubDivisionId"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Select
                                                    name="Scheme"
                                                    options={SchemeData}
                                                    value={SchemeId}
                                                    onChange={SchemeChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".SchemeId"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <Select
                                                    name="Pump"
                                                    options={PumpData}
                                                    value={PumpId}
                                                    onChange={PumpChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                    isDisabled={reportType === "waterLiftingReportData"}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".PumpId"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Select
                                                    name="Month"
                                                    options={monthData} placeholder="Select Month"
                                                    value={month}
                                                    onChange={MonthChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                    isDisabled={reportType === "monthlyUtilizationReportData" ||
                                                        reportType === "waterLiftingReportData" || reportType === "logBookReportData"
                                                        || reportType === "expenditureReportData"}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".month"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <Select
                                                    name="Year"
                                                    options={yearData} placeholder="Select Year"
                                                    value={year}
                                                    onChange={YearChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                    isDisabled={reportType === "waterLiftingReportData" || reportType === "logBookReportData"
                                                        || reportType === "expenditureReportData"}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".year"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                From Date
                                                <DatePicker
                                                    id="fromDate"
                                                    selected={fromDate}
                                                    onChange={date => setFromDate(date)}
                                                    dateFormat="MM/dd/yyyy"
                                                    disabled={reportType === "dailyUtilizationReportData" || reportType === "monthlyUtilizationReportData"}
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="scroll"
                                                />
                                            </TableCell>
                                            <TableCell>
                                                To Date
                                                <DatePicker
                                                    id="toDate"
                                                    selected={toDate}
                                                    onChange={date => setToDate(date)}
                                                    dateFormat="MM/dd/yyyy"
                                                    disabled={reportType === "dailyUtilizationReportData" ||
                                                        reportType === "monthlyUtilizationReportData" || reportType === "logBookReportData"}
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="scroll"
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={4} align="center">
                                                <MDBox mt={4} mb={1} textAlign="center">
                                                    {loading ?
                                                        <Circles
                                                            heigth="20"
                                                            width="20"
                                                            color='grey'
                                                            ariaLabel='loading'
                                                        />

                                                        :
                                                        <MDButton color="info" onClick={submitHandler}>
                                                            Show
                                                        </MDButton>
                                                    }
                                                    <br />
                                                    {errors ? <div style={{ fontSize: '14px', color: 'red', fontWeight: 'bold' }}>{errors[".invalid"]}</div> : null}
                                                </MDBox>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                <TableContainer component={Paper} style={{ width: '98%', alignSelf: 'center' }}>
                                    <Table>
                                        <TableRow>
                                            <TableCell>
                                                {showDailyUtilizationReport ? <ShowDailyUtilizationReport /> : null}
                                                {showMonthlyUtilizationReport ? <ShowMonthlyUtilizationReport /> : null}
                                                {showWaterLiftingReport ? <ShowWaterLiftingReport /> : null}
                                                {showLogBookReport ? <ShowLogBookReport /> : null}
                                                {showExpenditureReport ? <ShowExpenditureReport /> : null}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="center">
                                                {showDailyUtilizationReport ?
                                                    <ReactHTMLTableToExcel table="dvDailyUtilizationReport" className="info"
                                                        filename="ReportData" sheet="Sheet" buttonText="EXPORT TO EXCEL"
                                                    />
                                                    : null}
                                                {showMonthlyUtilizationReport ?
                                                    <ReactHTMLTableToExcel table="dvMonthlyUtilizationReport" className="info"
                                                        filename="ReportData" sheet="Sheet" buttonText="EXPORT TO EXCEL"
                                                    />
                                                    : null}
                                                {showWaterLiftingReport ?
                                                    <ReactHTMLTableToExcel table="dvWaterLiftingReport" className="info"
                                                        filename="ReportData" sheet="Sheet" buttonText="EXPORT TO EXCEL"
                                                    />
                                                    : null}
                                                {showLogBookReport ?
                                                    <ReactHTMLTableToExcel table="dvLogBookReport" className="info"
                                                        filename="ReportData" sheet="Sheet" buttonText="EXPORT TO EXCEL"
                                                    />
                                                    : null}
                                                {showExpenditureReport ?
                                                    <ReactHTMLTableToExcel table="dvExpenditureReport" className="info"
                                                        filename="ReportData" sheet="Sheet" buttonText="EXPORT TO EXCEL"
                                                    />
                                                    : null}
                                            </TableCell>
                                        </TableRow>
                                    </Table>
                                </TableContainer>
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default Reports;
