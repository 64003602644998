// import { useNavigate } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import MDButton from 'components/MDButton';
import MDInput from "components/MDInput";
import { Oval } from 'react-loader-spinner';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as moment from 'moment';
import * as service from '../../services/service'

function PumpLog() {
    const [state, setState] = useState({ starttime: '', stoptime: '' });
    const [CEData, setCEData] = useState([]);
    const [SEData, setSEData] = useState([]);
    const [MechanicalDivisionData, setMechanicalDivisionData] = useState([]);
    const [MechanicalSubDivisionData, setMechanicalSubDivisionData] = useState([]);
    const [CEId, setCEId] = useState('');
    const [SEId, setSEId] = useState('');
    const [MechanicalDivisionId, setMechanicalDivisionId] = useState('');
    const [MechanicalSubDivisionId, setMechanicalSubDivisionId] = useState('');
    const [SchemeData, setSchemeData] = useState([]);
    const [SchemeId, setSchemeId] = useState('');
    const [PumpData, setPumpData] = useState([]);
    const [PumpId, setPumpId] = useState('');
    const [workDate, setWorkDate] = useState(new Date());
    // const [data, setData] = useState([]);
    const [LogTimes, setLogTimes] = useState([]);
    const [IncomingRed, setIncomingRed] = useState([]);
    const [IncomingYellow, setIncomingYellow] = useState([]);
    const [IncomingBlue, setIncomingBlue] = useState([]);
    const [IncomingPower, setIncomingPower] = useState([]);
    const [PumpRed, setPumpRed] = useState([]);
    const [PumpYellow, setPumpYellow] = useState([]);
    const [PumpBlue, setPumpBlue] = useState([]);
    const [PumpPower, setPumpPower] = useState([]);
    const [PumpPressure, setPumpPressure] = useState([]);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const logTimeData = ["0.00", "1.00", "2.00", "3.00", "4.00", "5.00", "6.00", "7.00", "8.00", "9.00", "10.00",
        "11.00", "12.00", "13.00", "14.00", "15.00", "16.00", "17.00", "18.00", "19.00", "20.00",
        "21.00", "22.00", "23.00"];
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    // const navigate = useNavigate();
    const getCEs = () => {
        fetch(
            service.CE_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.ceId, label: item.ce }));
                setCEData(data2);
            });
    };

    const getSEs = (id) => {
        fetch(
            service.GET_SE_BY_CE_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CEId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.seId, label: item.se }));
                setSEData(data2);
            });
    };
    const getMechanicalDivisions = (id) => {
        fetch(
            service.GET_MECHANICAL_DIVISION_BY_SE_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    SEId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.mechanicalDivisionId, label: item.mechanicalDivision }));
                setMechanicalDivisionData(data2);
            });
    };

    const getMechanicalSubDivisions = (id) => {
        fetch(
            service.GET_MECHANICAL_SUB_DIVISION_BY_MECH_DIV_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    MechanicalDivisionId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.mechanicalSubDivisionId, label: item.mechanicalSubDivision }));
                setMechanicalSubDivisionData(data2);
            });
    };

    const getSchemes = () => {
        const errs = {};
        fetch(
            service.GET_SCHEME_ALLOCATION_TO_MECHSUBDIV_BY_SUBDIVID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    MechanicalSubDivisionId: JSON.parse(sessionStorage.getItem('userData')).mechanicalSubDivisionId
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.schemeId, label: item.scheme }));
                setSchemeData(data2);
            })
            .catch(err => {
                if (err) {
                    errs[".invalid"] = err;
                    setErrors(err);
                };
            });
    };

    const getPumps = (id) => {
        const errs = {};
        fetch(
            service.PUMP_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    SchemeId: id
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.pumpId, label: item.pump }));
                setPumpData(data2);
            })
            .catch(err => {
                if (err) {
                    errs[".invalid"] = err;
                    setErrors(err);
                };
            });
    };

    const getDetails = (shId, pmId, wdt) => {
        setLoading(true);
        setTimeout(() => setLoading(false), 5000)
        const errs = {};
        fetch(
            service.PUMP_LOG_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    SchemeId: shId,
                    PumpId: pmId,
                    WorkDate: moment(wdt).format("MM/DD/YYYY"),
                })
            })
            .then(res => res.json())
            .then(result => {
                // setData(result);               
                if (result[0].message.messageType === 'Success') {
                    setState({ ...state, starttime: result[0].pumpStartTime, stoptime: result[0].pumpStopTime });
                    result.forEach((element, index) => {
                        LogTimes[index] = element.logTime;
                        setLogTimes([...LogTimes]);
                        IncomingRed[index] = element.incomingRed;
                        setIncomingRed([...IncomingRed]);
                        IncomingYellow[index] = element.incomingYellow;
                        setIncomingYellow([...IncomingYellow]);
                        IncomingBlue[index] = element.incomingBlue;
                        setIncomingBlue([...IncomingBlue]);
                        IncomingPower[index] = element.incomingPower;
                        setIncomingPower([...IncomingPower]);
                        PumpRed[index] = element.pumpRed;
                        setPumpRed([...PumpRed]);
                        PumpYellow[index] = element.pumpYellow;
                        setPumpYellow([...PumpYellow]);
                        PumpBlue[index] = element.pumpBlue;
                        setPumpBlue([...PumpBlue]);
                        PumpPower[index] = element.pumpPower;
                        setPumpPower([...PumpPower]);
                        PumpPressure[index] = element.pumpGaugePressure;
                        setPumpPressure([...PumpPressure]);
                    })
                }
                else {
                    setState({ ...state, starttime: '', stoptime: '' });
                    logTimeData.forEach((element, index) => {
                        LogTimes[index] = element;
                        setLogTimes([...LogTimes]);
                        IncomingRed[index] = '';
                        setIncomingRed([...IncomingRed]);
                        IncomingYellow[index] = '';
                        setIncomingYellow([...IncomingYellow]);
                        IncomingBlue[index] = '';
                        setIncomingBlue([...IncomingBlue]);
                        IncomingPower[index] = '';
                        setIncomingPower([...IncomingPower]);
                        PumpRed[index] = '';
                        setPumpRed([...PumpRed]);
                        PumpYellow[index] = '';
                        setPumpYellow([...PumpYellow]);
                        PumpBlue[index] = '';
                        setPumpBlue([...PumpBlue]);
                        PumpPower[index] = '';
                        setPumpPower([...PumpPower]);
                        PumpPressure[index] = '';
                        setPumpPressure([...PumpPressure]);
                    });
                };
            })
            .catch(err => {
                if (err) {
                    errs[".invalid"] = err;
                    setErrors(err);
                };
            });
        setLoading(false);
    };

    // const getDetailsById = (id) => {
    //     setLoading(true);
    //     const errs = {};
    //     fetch(
    //         service.GET_PUMP_WORK_DONE_BY_ID,
    //         {
    //             method: 'POST',
    //             headers: {
    //                 'Accept': 'application/json',
    //                 'Content-Type': 'application/json'
    //             },
    //             body: JSON.stringify({
    //                 WorkDoneId: id,
    //             })
    //         })
    //         .then(res => res.json())
    //         .then(result => {
    //             if (result.message.messageType === 'Success') {
    //                 setState({
    //                     ...state, totalhours: result.totalHours
    //                 });
    //                 const rs = ({ value: result.reasonId, label: result.reason });
    //                 setReasonId(rs);
    //                 setWorkDate(new Date(result.workDate));
    //             }
    //             else {
    //                 errs[".invalid"] = result.message.message;
    //                 setErrors(errs);
    //             }
    //         });
    //     setLoading(false);
    // };

    // const handleEdit = (id) => {
    //     getDetailsById(id);
    //     localStorage.setItem("ID", JSON.stringify(id));
    // }

    useEffect(() => {
        setLoading(true);
        getCEs();
        const cc = ({ label: JSON.parse(sessionStorage.getItem('userData')).ce, value: JSON.parse(sessionStorage.getItem('userData')).ceId });
        setCEId(cc);
        getSEs(cc.value);
        const sc = ({ label: JSON.parse(sessionStorage.getItem('userData')).se, value: JSON.parse(sessionStorage.getItem('userData')).seId });
        setSEId(sc);
        getMechanicalDivisions(sc.value);
        const dv = ({ label: JSON.parse(sessionStorage.getItem('userData')).mechanicalDivision, value: JSON.parse(sessionStorage.getItem('userData')).mechanicalDivisionId });
        setMechanicalDivisionId(dv);
        getMechanicalSubDivisions(dv.value);
        const sb = ({ label: JSON.parse(sessionStorage.getItem('userData')).mechanicalSubDivision, value: JSON.parse(sessionStorage.getItem('userData')).mechanicalSubDivisionId });
        setMechanicalSubDivisionId(sb);
        getSchemes();
        setLoading(false);
    }, []);

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!MechanicalSubDivisionId) {
            isValid = false;
            errs[".MechanicalSubDivisionId"] = "Please select mechanical sub division";
        }
        if (!SchemeId) {
            isValid = false;
            errs[".SchemeId"] = "Please select Scheme";
        }
        if (!PumpId) {
            isValid = false;
            errs[".PumpId"] = "Please select pump";
        }
        if (!state.starttime) {
            isValid = false;
            errs[".starttime"] = "Please enter pump start time";
        }
        if (!state.stoptime) {
            isValid = false;
            errs[".stoptime"] = "Please enter pump stop time";
        }
        setErrors(errs);
        return isValid;
    }
    const changeHandler = (event) => {
        if (event.target.name === 'totalhours') {
            if (Number.isNaN(parseFloat(event.target.value))) {
                // eslint-disable-next-line
                alert("Please enter numbers only");
                event.target.value = ""; // eslint-disable-line no-param-reassign
            }
        }
        setState({ ...state, [event.target.name]: event.target.value });
    };
    const CEChangeHandler = (ev) => {
        setCEId(ev);
        getSEs(ev.value);
    }
    const SEChangeHandler = (ev) => {
        setSEId(ev);
        setMechanicalDivisionData([]);
        getMechanicalDivisions(ev.value);
    }
    const MechanicalDivisionChangeHandler = (ev) => {
        setMechanicalDivisionId(ev);
        setMechanicalSubDivisionData([]);
        getMechanicalSubDivisions(ev.value);
    }
    const MechanicalSubDivisionChangeHandler = (ev) => {
        setMechanicalSubDivisionId(ev);
        getSchemes();
    }
    const SchemeChangeHandler = (ev) => {
        setSchemeId(ev);
        getPumps(ev.value);
    }
    const PumpChangeHandler = (ev) => {
        setPumpId(ev);
    }
    const WorkDateChangeHandler = (ev) => {
        setWorkDate(ev);
        getDetails(SchemeId.value, PumpId.value, ev);
    }
    const IncomingRedChangeHandler = (event, index) => {
        if (Number.isNaN(parseFloat(event.target.value))) {
            // eslint-disable-next-line
            alert("Please enter numbers only");
            event.target.value = ""; // eslint-disable-line no-param-reassign
        }
        else {
            let updatedObject = IncomingRed[index];
            updatedObject = event.target.value;
            IncomingRed[index] = updatedObject;
        }
        setIncomingRed([...IncomingRed]);
    };
    const IncomingYellowChangeHandler = (event, index) => {
        if (Number.isNaN(parseFloat(event.target.value))) {
            // eslint-disable-next-line
            alert("Please enter numbers only");
            event.target.value = ""; // eslint-disable-line no-param-reassign
        }
        else {
            let updatedObject = IncomingYellow[index];
            updatedObject = event.target.value;
            IncomingYellow[index] = updatedObject;
        }
        setIncomingYellow([...IncomingYellow]);
    };
    const IncomingBlueChangeHandler = (event, index) => {
        if (Number.isNaN(parseFloat(event.target.value))) {
            // eslint-disable-next-line
            alert("Please enter numbers only");
            event.target.value = ""; // eslint-disable-line no-param-reassign
        }
        else {
            let updatedObject = IncomingBlue[index];
            updatedObject = event.target.value;
            IncomingBlue[index] = updatedObject;
        }
        setIncomingBlue([...IncomingBlue]);
    };
    const IncomingPowerChangeHandler = (event, index) => {
        if (Number.isNaN(parseFloat(event.target.value))) {
            // eslint-disable-next-line
            alert("Please enter numbers only");
            event.target.value = ""; // eslint-disable-line no-param-reassign
        }
        else {
            let updatedObject = IncomingPower[index];
            updatedObject = event.target.value;
            IncomingPower[index] = updatedObject;
        }
        setIncomingPower([...IncomingPower]);
    };
    const PumpRedChangeHandler = (event, index) => {
        if (Number.isNaN(parseFloat(event.target.value))) {
            // eslint-disable-next-line
            alert("Please enter numbers only");
            event.target.value = ""; // eslint-disable-line no-param-reassign
        }
        else {
            let updatedObject = PumpRed[index];
            updatedObject = event.target.value;
            PumpRed[index] = updatedObject;
        }
        setPumpRed([...PumpRed]);
    };
    const PumpYellowChangeHandler = (event, index) => {
        if (Number.isNaN(parseFloat(event.target.value))) {
            // eslint-disable-next-line
            alert("Please enter numbers only");
            event.target.value = ""; // eslint-disable-line no-param-reassign
        }
        else {
            let updatedObject = PumpYellow[index];
            updatedObject = event.target.value;
            PumpYellow[index] = updatedObject;
        }
        setPumpYellow([...PumpYellow]);
    };
    const PumpBlueChangeHandler = (event, index) => {
        if (Number.isNaN(parseFloat(event.target.value))) {
            // eslint-disable-next-line
            alert("Please enter numbers only");
            event.target.value = ""; // eslint-disable-line no-param-reassign
        }
        else {
            let updatedObject = PumpBlue[index];
            updatedObject = event.target.value;
            PumpBlue[index] = updatedObject;
        }
        setPumpBlue([...PumpBlue]);
    };
    const PumpPowerChangeHandler = (event, index) => {
        if (Number.isNaN(parseFloat(event.target.value))) {
            // eslint-disable-next-line
            alert("Please enter numbers only");
            event.target.value = ""; // eslint-disable-line no-param-reassign
        }
        else {
            let updatedObject = PumpPower[index];
            updatedObject = event.target.value;
            PumpPower[index] = updatedObject;
        }
        setPumpPower([...PumpPower]);
    };
    const PumpPressureChangeHandler = (event, index) => {
        if (Number.isNaN(parseFloat(event.target.value))) {
            // eslint-disable-next-line
            alert("Please enter numbers only");
            event.target.value = ""; // eslint-disable-line no-param-reassign
        }
        else {
            let updatedObject = PumpPressure[index];
            updatedObject = event.target.value;
            PumpPressure[index] = updatedObject;
        }
        setPumpPressure([...PumpPressure]);
    };

    const clearHandler = () => {
        localStorage.setItem('ID', null);
        setState({ ...state, starttime: '', stoptime: '' });
        setWorkDate(new Date());
        const sc = ({ value: null, label: "Select Scheme" });
        setSchemeId(sc);
        const pm = ({ value: null, label: "Select Pump" });
        setPumpId(pm);
        // setData([]);
        setIncomingBlue([]);
        setIncomingRed([]);
        setIncomingYellow([]);
        setIncomingPower([]);
        setPumpBlue([]);
        setPumpRed([]);
        setPumpYellow([]);
        setPumpPower([]);
        setPumpPressure([]);
    }

    const submitHandler = e => {
        e.preventDefault();
        const errs = {};
        let flag = true;
        if (validate()) {
            // eslint-disable-next-line
            console.log(LogTimes); // do not remove
            setLoading(true);
            fetch(
                service.DELETE_PUMP_LOG,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        PumpId: PumpId.value,
                        SchemeId: SchemeId.value,
                        WorkDate: moment(workDate).format("MM/DD/YYYY"),
                    })
                })
                .then(res => res.json())
                .then(respo => {
                    if (respo.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(respo.message);
                    }
                    else if (respo.messageType === 'Success') {
                        LogTimes.forEach((element, index) => {
                            fetch(
                                service.ADD_UPDATE_PUMP_LOG,
                                {
                                    method: 'POST',
                                    headers: {
                                        'Accept': 'application/json',
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify({
                                        LogId: 0,
                                        PumpId: PumpId.value,
                                        SchemeId: SchemeId.value,
                                        MechanicalSubDivisionId: JSON.parse(sessionStorage.getItem('userData')).mechanicalSubDivisionId,
                                        LogTime: element,
                                        WorkDate: moment(workDate).format("MM/DD/YYYY"),
                                        IncomingRed: IncomingRed[index],
                                        IncomingBlue: IncomingBlue[index],
                                        IncomingYellow: IncomingYellow[index],
                                        IncomingPower: IncomingPower[index],
                                        PumpBlue: PumpBlue[index],
                                        PumpRed: PumpRed[index],
                                        PumpYellow: PumpYellow[index],
                                        PumpPower: PumpPower[index],
                                        PumpGaugePressure: PumpPressure[index],
                                        PumpStartTime: state.starttime,
                                        PumpStopTime: state.stoptime,
                                        AddedBy: JSON.parse(sessionStorage.getItem('userData')).userId,
                                        UpdatedBy: JSON.parse(sessionStorage.getItem('userData')).userId,
                                    })
                                })
                                .then(res => res.json())
                                .then(result => {
                                    if (result.messageType === 'Failed') {
                                        flag = false;
                                        // eslint-disable-next-line
                                        alert(result.message);
                                    }
                                    else {
                                        flag = false;
                                        errs[".invalid"] = result.message;
                                        setErrors(errs);
                                    }
                                });
                        });
                    }
                });

            if (flag) {
                // eslint-disable-next-line
                alert("Data Saved Successfully");
                window.location.reload(false);
            }
            setLoading(false);
        }
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Pump Log
                                </MDTypography>
                            </MDBox>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell width="50%">
                                                <Select
                                                    name="CE"
                                                    options={CEData} placeholder="Select CE"
                                                    value={CEId}
                                                    onChange={CEChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                    isDisabled
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".ceId"]}</div>
                                            </TableCell>
                                            <TableCell width="50%">
                                                <Select
                                                    name="SE"
                                                    options={SEData} placeholder="Select SE"
                                                    value={SEId}
                                                    onChange={SEChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                    isDisabled
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".seId"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Select
                                                    name="Mechanical Division"
                                                    options={MechanicalDivisionData} placeholder="Select Mechanical Division"
                                                    value={MechanicalDivisionId}
                                                    onChange={MechanicalDivisionChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                    isDisabled
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".MechanicalDivisionId"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <Select
                                                    name="Mechanical Sub Division"
                                                    options={MechanicalSubDivisionData} placeholder="Select Mechanical Sub Division"
                                                    value={MechanicalSubDivisionId}
                                                    onChange={MechanicalSubDivisionChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                    isDisabled
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".MechanicalSubDivisionId"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Select
                                                    name="Scheme"
                                                    options={SchemeData} placeholder="Select Scheme"
                                                    value={SchemeId}
                                                    onChange={SchemeChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".SchemeId"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <Select
                                                    name="Pump"
                                                    options={PumpData} placeholder="Select Pump"
                                                    value={PumpId}
                                                    onChange={PumpChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".PumpId"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                Work Date
                                                <DatePicker
                                                    id="workDate"
                                                    selected={workDate}
                                                    onChange={WorkDateChangeHandler}
                                                    dateFormat="MM/dd/yyyy"
                                                    tabIndex={0}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".workDate"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <MDInput label="Pump Start Time" fullWidth value={state.starttime || 0}
                                                    name="starttime"
                                                    onChange={changeHandler}
                                                    id="starttime"
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".starttime"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <MDInput label="Pump Stop Time" fullWidth value={state.stoptime || 0}
                                                    name="stoptime"
                                                    onChange={changeHandler}
                                                    id="stoptime"
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".stoptime"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan="2">
                                                {loading ? 
                                                <Oval
                                                    heigth="200"
                                                    width="200"
                                                    color='grey'
                                                    ariaLabel='loading'
                                                /> :
                                                    <Table aria-label="simple table">
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}>Log Time</TableCell>
                                                            <TableCell colSpan="3" align="center" style={{ fontWeight: 'bold' }}>Incoming Voltage (HT Side)</TableCell>                                                            
                                                            <TableCell style={{ fontWeight: 'bold' }}>Incoming Power Factor COS</TableCell>
                                                            <TableCell colSpan="3" align="center" style={{ fontWeight: 'bold' }}>Current</TableCell>                                                            
                                                            <TableCell style={{ fontWeight: 'bold' }}>Pump Power Power Factor COS</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>Pump Gauge Pressure</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold' }}/>
                                                            <TableCell align="center" style={{ fontWeight: 'bold' }}>Rv</TableCell>
                                                            <TableCell align="center" style={{ fontWeight: 'bold' }}>Yv</TableCell>
                                                            <TableCell align="center" style={{ fontWeight: 'bold' }}>Bv</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }} />
                                                            <TableCell align="center" style={{ fontWeight: 'bold' }}>Ra</TableCell>
                                                            <TableCell align="center" style={{ fontWeight: 'bold' }}>Ya</TableCell>
                                                            <TableCell align="center" style={{ fontWeight: 'bold' }}>Ba</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }} />
                                                            <TableCell style={{ fontWeight: 'bold' }}/>
                                                        </TableRow>
                                                        <TableBody>
                                                            {logTimeData.map((row, index) => (
                                                                <TableRow
                                                                    key={row}
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                >
                                                                    <TableCell>{row}</TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Incoming Red" fullWidth value={IncomingRed[index] || ''}
                                                                            name="IncomingRed"
                                                                            onChange={ev => IncomingRedChangeHandler(ev, index)}
                                                                            id="IncomingRed"
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".IncomingRed"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Incoming Yellow" fullWidth value={IncomingYellow[index] || ''}
                                                                            name="IncomingYellow"
                                                                            onChange={ev => IncomingYellowChangeHandler(ev, index)}
                                                                            id="IncomingYellow"
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".IncomingYellow"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Incoming Blue" fullWidth value={IncomingBlue[index] || ''}
                                                                            name="IncomingBlue"
                                                                            onChange={ev => IncomingBlueChangeHandler(ev, index)}
                                                                            id="IncomingBlue"
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".IncomingBlue"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Incoming Power" fullWidth value={IncomingPower[index] || ''}
                                                                            name="IncomingPower"
                                                                            onChange={ev => IncomingPowerChangeHandler(ev, index)}
                                                                            id="IncomingPower"
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".IncomingPower"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Pump Red" fullWidth value={PumpRed[index] || ''}
                                                                            name="PumpRed"
                                                                            onChange={ev => PumpRedChangeHandler(ev, index)}
                                                                            id="PumpRed"
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".PumpRed"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Pump Yellow" fullWidth value={PumpYellow[index] || ''}
                                                                            name="PumpYellow"
                                                                            onChange={ev => PumpYellowChangeHandler(ev, index)}
                                                                            id="PumpYellow"
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".PumpYellow"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Pump Blue" fullWidth value={PumpBlue[index] || ''}
                                                                            name="PumpBlue"
                                                                            onChange={ev => PumpBlueChangeHandler(ev, index)}
                                                                            id="PumpBlue"
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".PumpBlue"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Pump Power" fullWidth value={PumpPower[index] || ''}
                                                                            name="PumpPower"
                                                                            onChange={ev => PumpPowerChangeHandler(ev, index)}
                                                                            id="PumpPower"
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".PumpPower"]}</div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDInput label="Pump Pressure" fullWidth value={PumpPressure[index] || ''}
                                                                            name="PumpPressure"
                                                                            onChange={ev => PumpPressureChangeHandler(ev, index)}
                                                                            id="PumpPressure"
                                                                        />
                                                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".PumpPressure"]}</div>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                <MDBox mt={4} mb={1} textAlign="center">
                                    {loading ? <Oval
                                        heigth="20"
                                        width="20"
                                        color='grey'
                                        ariaLabel='loading'
                                    /> :
                                        <div>
                                            <MDButton color="info" onClick={submitHandler}>
                                                SAVE
                                            </MDButton>&nbsp;&nbsp;&nbsp;&nbsp;
                                            <MDButton color="info" onClick={clearHandler}>
                                                CLEAR
                                            </MDButton>
                                        </div>
                                    }
                                    {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                </MDBox>
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox >
            <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
                <Footer />
            </div>
        </DashboardLayout >
    );
}
export default PumpLog;